@charset "UTF-8";
// ==========================================================================
// Business Customer
// 書店・楽器店の方へ
// ==========================================================================
.s-bs-box {
	line-height: 1.7;
	.s-bs-box-item__cat {
		span {
			background: #9b9b9b;
			border-radius: $radius;
			text-align: center;
			display: inline-block;
			color: #fff;
			font-size: 1.2rem;
			min-width: 6.8rem;
			padding: .3rem .2rem;
			&.s-bs-box-item__cat--mook {
				background-color: #FBB40F;
			}
			&.s-bs-box-item__cat--score {
				background-color: #E12C72;
			}
			&.s-bs-box-item__cat--book {
				background-color: #6AC80B;
			}
			&.s-bs-box-item__cat--fair {
				background-color: #f72410;
			}
		}
	}
	@media #{$sp} {
		.s-bs-box-item {
			text-align: left;
			padding: .2rem 0;
			.s-bs-box-item__date {
				@include tableCell(middle);
				padding: 0 1rem 0 0;
			}
			.s-bs-box-item__cat {
				@include tableCell(middle);
			}
			.s-bs-box-item__notice {
				@include tableCell(middle);
				padding-left: 1.5rem;
			}
			.s-bs-box-item__title {
				padding: 1rem 0 0;
				span {
					display: block;
					margin: 0 1.2em 0 0;
				}
			}
		}
	}
	@media #{$tbpc} {
		.s-bs-box-item {
			@include table(100%);
			text-align: left;
			padding: .2rem 0;
			.s-bs-box-item__date {
				@include tableCell(middle);
				width: 11.5%;
			}
			.s-bs-box-item__cat {
				@include tableCell(middle);
				width: 11%;
			}
			.s-bs-box-item__notice {
				@include tableCell(middle);
				width: 10%;
			}
			.s-bs-box-item__title {
				@include tableCell(middle);
				span {
					margin: 0 1.2em 0 0;
				}
				&--finished {
					padding-left: 1rem;
					font-size: 1.1rem;
				}
			}
		}
	}
	.s-bs-box__title {
		font-size: 1.4rem;
		font-weight: bold;
		padding: 0;
		margin: 7rem 0 3.2rem;
		@media #{$sp} {
			margin: 3.5rem 0 1.6rem;
		}
		&:before {
			display: none;
		}
	}
	.s-bs-box__list {
		margin: 0 0 1.3rem;
	}
	.s-bs-box__remarks {
		p {
			font-size: 1.2rem;
			margin: 0;
			text-indent: -1em;
			padding: 0 0 0 1em;
		}
	}
}