@charset "UTF-8";
// ==========================================================================
// Check
// 最近チェックした商品
// ==========================================================================

.p-check-list {
	@media #{$tbpc} {
		border-radius: $radius;
		border: #979797 solid 1px;
		box-sizing: border-box;
		margin-top: -5rem;
		padding: 4.2rem 5rem 0;
	}
	
	@media #{$sp} {
		.c-item-list {
			border: $colorBase solid 1px;
			margin: 0 $spSpace $spSpace;
			
			.c-item-list__item {
				border-bottom: $colorBase solid 1px;
				
				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
}