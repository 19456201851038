@charset "UTF-8";
// ==========================================================================
// Grid
// グリッド・セクションの設定
// ==========================================================================

.c-box {
	@media #{$sp} {
		padding: $spSpace 0 0;
	}
	
	@media #{$tbpc} {
		width: 100%;
		margin: 0 0 5rem;
	}
}

.c-box-inner {
	margin: 0 0 2rem;
	
	@media #{$tbpc} {
		margin: 0 0 2.8rem;
	}
}