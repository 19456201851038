@charset "UTF-8";
// ==========================================================================
// Search Box
// 検索ボックスの設定
// ==========================================================================

.search-box {}

.search-box__body {
	position: relative; //for suggest
}

.search-box__input {
	border: $colorBase solid 1px;
	border-radius: $radius;
	background: #fff;
	position: relative;
	width: 100%;
	vertical-align: middle;
	
	input[type=text] {
		border: none;
		box-sizing: border-box;
		width: 100%;
		color: $colorBase;
	}
	
	@media #{$sp} {
		input[type=text] {
			font-size: 1.5rem;
			padding: 1.3rem 4rem 1.2rem 1.6rem;
			line-height: 1.3;
			
			@include placeholder {
				font-size: 1.1rem;
				color: #c8c8c8;
			}
		}
	}
	
	@media #{$tbpc} {
		input[type=text] {
			@include placeholder {
				color: #ddd;
			}
			
			font-size: 1.8rem;
			line-height: 1.3;
			padding: 1.1rem 0 1.1rem 2rem;
		}
		
	}
}
.android .search-box__input {
	input[type=text] {
		@include placeholder {
			font-size: 1.5rem;
		}
	}
}

.search-box__reset {
	display: none;
	background: url(images/icon_search_reset.png) no-repeat scroll 50% 50%;
	background-size: 15px 15px;
	width: 20px;
	height: 20px;
	position: absolute;
	margin: auto 0;
	top: 0;
	bottom: 0;
	right: 5.6rem;

	@media #{$tbpc} {
		display: none;
	}
}

.search-box__button {
	@include animeAlpha;
	-webkit-appearance: none;
	border: none;
	background-color: none;
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	
	&:hover {
		@include animeAlphaHover(80);
	}
	
	@media #{$sp} {
		background: url(images/icon_search_sp.png) no-repeat scroll 1rem 50%;
		background-size: 20px 20px;
		width: 4.4rem;
	}
	@media #{$tbpc} {
		//border-radius: 0 $radius $radius 0;
		background: $colorBase url(images/icon_search.png) no-repeat scroll 50% 50%;
		background-size: 19px 19px;
		width: 4.5rem;
	}
}

//サジェスト
.js-suggest-result {
	
	.loading {
		background: #fff;
		box-sizing: border-box;
		border: $bdrColor solid 1px;
		border-radius: 2px;
		padding: 10px;
		position: absolute;
		top: 50px;
		left: 0;
		text-align: center;
		width: 100%;
		
		@media #{$tbpc} {
			top: 54px;
		}
	}
	
	&__body {
		background: #fff;
		box-sizing: border-box;
		border: $bdrColor solid 1px;
		border-radius: 2px;
		position: absolute;
		top: 50px;
		left: 0;
		text-align: left;
		width: 100%;
		height: 240px;
		overflow: auto;
		z-index: 5000;
		
		@media #{$tbpc} {
			top: 47px;
			height: auto;
		}
		
		ul {
			
			li {
				
				a {
					box-sizing: border-box;
					border-bottom: $bgThinColor solid 1px;
					color: $colorBase;
					display: block;
					font-size: 1.4rem;
					padding: 0.2rem 0.8rem;
					width: 100%;
					outline: none;
					
					&:hover {
						background: $bdrColor;
						text-decoration: none;
					}
				}
			}
		}
	}
	
	&--focus {
		background: $bdrColor;
		text-decoration: none;
	}
}