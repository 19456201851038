@charset "UTF-8";
// ==========================================================================
// Nav
// ナビゲーション・メニューの設定
// ==========================================================================

@media #{$sp} {
	.header-nav {
		display: none; //TOP以外は非表示
	}
	.p-home-header-nav {
		.header-nav {
			display: block; //TOPで表示
		}
	}
	
	.header-nav__item-inner {
		a {
			display: block;
			color: #fff;
			font-size: 1.5rem;
			font-weight: bold;
			padding: 1.2rem 1.5rem;
			
			&:hover {
				text-decoration: none;
			}
			
			br {
				display: none;
			}
		}
		.header-nav-item-inner__title {
			position: relative;
			
			&:after {
				content: "";
				display: block;
				background: url(images/icon_open_close_sp@2x.png) no-repeat scroll 0 bottom;
				background-size: 15px 30px;
				width: 1.5rem;
				height: 1.5rem;
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto 0;
			}
		}
		.js-header-nav-item-inner__title--active {
			&:after {
				background: url(images/icon_open_close_sp@2x.png) no-repeat scroll 0 0;
				background-size: 15px 30px;
			}
		}
		
	}
	.header-nav__item {
		&:last-child {
			.header-nav-item-inner__title:after {
				background: none;
			}
		}
	}
	
	@each $catItem in $arrayCat{
		$indexKey: index($arrayCat, $catItem);
		.header-nav__item.#{$catItem} a{
			background: nth($arrayCatColor, $indexKey);

			@if $catItem == "magazine" {
				@include magazineGrd;
			} @else if $catItem == "books" {
				@include booksGrd;
			} @else if $catItem == "music_score" {
				@include musicScoreGrd;
			} @else if $catItem == "music_manual" {
				@include musicManualGrd;
			} @else if $catItem == "cd_dvd_othens" {
				@include CdDvdOthersGrd;
			}
		}
	}
	
	@each $name in $arrayCat{
		.header-nav__item.#{$name} {
			.header-nav__item-inner {
				p {
					background: url(images/icon_cat_#{$name}@2x.png) no-repeat scroll 0 50%;
					background-size: auto 120%;
					padding: 0 0 0 3.4rem;
				}
			}
		}
	}
	
	.header-mega-menu-close {
		display: none;
	}
	
	//メガメニュー
	.header-nav__item {
		.header-mega-menu {
			display: none; //いったん非表示
			
			a {
				background: #fff;
				border-bottom: $bdrThinColor solid 1px;
				border-left: $bdrThinColor solid 1px;
				border-right: $bdrThinColor solid 1px;
				color: $colorBase;
				display: block;
				font-weight: bold;
				padding: 1.2rem 1.5rem;
				
				&:hover {
					text-decoration: none;
				}
			}

			.children {
				a {
					background: $colorBgThin;
					font-weight: normal;
				}
			}
		}
	}
	
	@each $catItem in $arrayCat{
		$indexKey: index($arrayCat, $catItem);
		.header-nav__item.#{$catItem} .header-mega-menu{
			background: nth($arrayCatColor, $indexKey);

			@if $catItem == "magazine" {
				@include magazineGrd;
				.cat-item p {
					background: url(images/icon_links_magazine_sp.png) no-repeat scroll right 50%;
					background-size: 5px 8px;
				}
				
			} @else if $catItem == "books" {
				@include booksGrd;
				.cat-item p {
					background: url(images/icon_links_books_sp.png) no-repeat scroll right 50%;
					background-size: 5px 8px;
				}
				
			} @else if $catItem == "music_score" {
				@include musicScoreGrd;
				.cat-item p {
					background: url(images/icon_links_music_score_sp.png) no-repeat scroll right 50%;
					background-size: 5px 8px;
				}
				
			} @else if $catItem == "music_manual" {
				@include musicManualGrd;
				.cat-item p {
					background: url(images/icon_links_music_manual_sp.png) no-repeat scroll right 50%;
					background-size: 5px 8px;
				}
				
			} @else if $catItem == "cd_dvd_othens" {
				@include CdDvdOthersGrd;
			}
		}
	}
	
}


@media #{$tbpc} {
	.header-nav {
		background: #f1f1f1;
		position: relative;
		width: 100%;
		height: 5rem;
		padding: 0 0 1rem;
		z-index: 1200;
		min-width: $pcFullWidth + $pcWrapSpace * 2;

	}

	.header-nav__body {
//		display: flex;
//		flex-direction: row;
//		flex-wrap: wrap;
//		justify-content: space-between;
//		align-content: space-around;
		@include table(100%);
		table-layout: fixed;
		position: relative;

		.header-nav__item {
			@include tableCell(middle);
			max-width: 23.7rem;
			//width: 100%;
			box-sizing: border-box;
			padding: 0 .15rem;
			z-index: 5;
			
			&:first-child {
				padding: 0 .15rem 0 0;
			}
			&:last-child {
				padding: 0 0 0 .15rem;
			}

			.header-nav__item-inner {
				@include table(100%);

				a {
					@include tableCell(middle);
					@include animeAlpha;
					border-radius: $radius;
					box-sizing: border-box;
					width: 100%;
					height: 5rem;
					color: #fff;
					//font-weight: bold;
					font-size: 1.8rem;
					padding: 0 0 0 2rem;
					
					&:hover {
						//@include animeAlphaHover(88);
						text-decoration: none;
					}

					@media #{$pcSmall} {
						font-size: 1.5rem;
					}

					p {
						padding: 0 0 0 3.8rem;
						
						br {
							display: none;
						}
					}
				}
			}
		}
		
		@each $catItem in $arrayCat{
			$indexKey: index($arrayCat, $catItem);
			.header-nav__item.#{$catItem} .header-nav__item-inner a{
				background: nth($arrayCatColor, $indexKey);

				@if $catItem == "magazine" {
					@include magazineGrd;
				} @else if $catItem == "books" {
					@include booksGrd;
				} @else if $catItem == "music_score" {
					@include musicScoreGrd;
				} @else if $catItem == "music_manual" {
					@include musicManualGrd;
				} @else if $catItem == "cd_dvd_othens" {
					@include CdDvdOthersGrd;
				}
				
				&:hover {
					background: nth($arrayCatColor, $indexKey);
				}
				
			}
		}
		
		@each $name in $arrayCat{
			.header-nav__item.#{$name} .header-nav__item-inner {
				p {
					background: url(images/icon_cat_#{$name}@2x.png) no-repeat scroll 0 50%;
					background-size: auto 120%;
				}
			}
		}
	}

	//閉じるクリック
	.header-mega-menu-close {
		@include animeAlpha;
		opacity: 0;
		z-index: -1;
		display: block;
		position: absolute;
		top: 6.5rem;
		right: 2rem;
	}
	
	.js-header-mega-menu-close--active {
		@include animeAlphaHover(100);
		z-index: 4001;
		text-decoration: underline;
		cursor: pointer;
		
		&:hover {
			text-decoration: none;
		}
	}
	
	//オンマウス時のメニュー
	.header-mega-menu {
		//display: none;
		//z-index: 4000;
		@include animeAlpha;
		opacity: 0;
		z-index: -1;
		box-sizing: border-box;
		border-radius: $radius;
		background: #fff;
		position: absolute;
		top: 5rem;
		left: 0;
		width: 100%;
		padding: 2.5rem 4.2rem .8rem;
		visibility: hidden;
		
		//小カテ
		& > li {
			@include table(100%);
			padding: 0 0 2rem;
			
			& > .header-mega-menu__item {
				@include tableCell(top);
				box-sizing: border-box;
				width: 15rem;
				padding: 0 1rem 0 0;
				//white-space: nowrap;
			}

			& > div > a {
				font-size: 1.4rem;
				font-weight: bold;
				display: inline-block;
				
				&:hover {
					text-decoration: underline;
				}
				
				.count {
					font-size: 1.2rem;
					font-weight: normal;
				}
			}
		}
	}
	.header-mega-menu--active {
		@include animeAlphaHover(100);
		z-index: 4000;
		visibility: visible;
	}
	
	@each $catItem in $arrayCat{
	$indexKey: index($arrayCat, $catItem);
		.header-nav__item.#{$catItem} .header-mega-menu{
			border: nth($arrayCatColor, $indexKey) solid 5px;
			
			& > li > div > a {
				color: nth($arrayCatColor, $indexKey);
			}
			
		}
	}

	@each $catItem in $arrayCat{
		$indexKey: index($arrayCat, $catItem);
		.header-nav__item.#{$catItem} .header-mega-menu > li > .header-mega-menu__item{
			@if $catItem == "magazine" {
			} @else if $catItem == "books" {
				width: 20rem;
			} @else if $catItem == "music_score" {
			} @else if $catItem == "music_manual" {
			} @else if $catItem == "cd_dvd_othens" {
			}
		}
	}
	
	

	//children（孫カテ）
	.header-mega-menu .children {
		@include tableCell(top);
		padding: .1rem 0 0 2.2rem;

		li {
			display: inline-block;
			box-sizing: border-box;
			padding: 0 1.6rem .4rem 0;
			min-width: 16rem;

			a {
				color: $baseFontThinColor;
				font-size: 1.2rem;
				font-weight: normal;
				display: inline-block;
				
				.count {}
				
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	
	@each $name in $arrayCat{
		.header-nav__item.#{$name} .header-mega-menu .children {
			background: url(images/icon_links_#{$name}@2x.png) no-repeat scroll 0 .5rem;
			background-size: 8px 10px;
		}
	}
}



//ナビゲーションカスタマイズ（PCのみ）
@media #{$tbpc} {
	.p-home-header-nav {
		width: 97.2rem;
		margin: 4.5rem auto 0;
		
		.header-nav__body {
			-js-display: flex;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: space-around;
			width: 100%;
			
			.header-nav__item {
				display: block;
				width: 14.6rem;
				z-index: auto;
				
				.header-nav__item-inner {
					display: block;
					
					a {
						@include table(100%);
						border-radius: 7.3rem;
						width: 100%;
						height: 14.6rem;
						text-align: center;
						padding: 0;
						position: relative;
						z-index: 20;
						
						&:after {
							content: "";
							display: block;
							width: 5.2rem;
							height: 5.2rem;
							position: absolute;
							top: 1rem;
							left: 0;
							right: 0;
							margin: 0 auto;
						}
						
						p {
							@include tableCell(middle);
							box-sizing: border-box;
							background: none;
							padding: 7rem 0 0;
							width: 100%;
							height: 14.6rem;
							font-size: 1.8rem;
							line-height: 1.3;
							font-weight: normal;
							
							br {
								display: block;
							}
						}
					}
				}
			}
			
			@each $name in $arrayCat{
				.header-nav__item.#{$name} {
					a:after {
						background: url(images/icon_cat_#{$name}@2x.png) no-repeat scroll 50% 50%;
						background-size: 100% auto;
					}
				}
			}
			
			@each $catItem in $arrayCat{
				$indexKey: index($arrayCat, $catItem);
				.header-nav__item.#{$catItem} .header-nav-item-inner__title {
					//background: nth($arrayCatColor, $indexKey);
					&:before {
						content: "";
						color: #fff;
						display: block;
						font-size: 1.3rem;
						font-weight: bold;
						position: absolute;
						width: 100%;
						text-align: center;
						top: 6.4rem;
						
						@if $catItem == "magazine" {
							content: "Magazines";
						} @else if $catItem == "books" {
							content: "Books";
						} @else if $catItem == "music_score" {
							content: "Musical scores";
						} @else if $catItem == "music_manual" {
							content: "Music didactics";
						} @else if $catItem == "cd_dvd_othens" {
							content: "CD/DVD/Others";
						}
					}
				}
			}
		}
		
		//メガメニューの調整
		.header-mega-menu {
			top: 13.6rem;
			width: 350%;
		}
	}
}

//トップでは非表示
.p-home_nav {
	@media #{$tbpc} {
		.header-mega-menu-close {
			display: none;
		}
		.header-mega-menu {
			display: none;
		}
	}
}
