@charset "UTF-8";
// ==========================================================================
// Special List
// 特集一覧のパーツ
// ==========================================================================
.p-search-special {
	@media #{$sp} {
		background: #e5e5e5;
	}
}

@media #{$sp} {
	.c-special-list {
		box-sizing: border-box;
		width: 100%;
		padding: 0 $spSpace;
		li {
			padding: 0 0 $spSpace;
			a {
				display: block;
			}
		}
		.c-special-list-item__thumb {
			background: #000;
			width: 100%;
			height: 12.6rem;
			span {
				background-position: 50% 50%;
				background-size: cover;
				display: block;
				width: 100%;
				height: 100%;
			}
		}
		.c-special-list-item__title {
			background: #fff;
			color: $colorBase;
			text-decoration: underline;
			padding: 1.2rem;
			font-weight: normal;
		}
	}
	.c-special-list--bg {
		.c-special-list-item__title {
			border-right: $colorBdr solid 1px;
			border-left: $colorBdr solid 1px;
			border-bottom: $colorBdr solid 1px;
		}
	}
	//2列のパターン
	.c-special-list--sp-rows {
		@include flow(100%);
		letter-spacing: -.40em;
		li {
			width: 49%;
			padding: 0 1% .5rem 0;
			display: inline-block;
			vertical-align: top;
			&:nth-child(even) {
				padding: 0 0 .5rem 1%;
			}
			.c-special-list-item__thumb {
				height: 7.8rem;
			}
			.c-special-list-item__title {
				box-sizing: border-box;
				background: #fff;
				border: none;
				font-size: 1.2rem;
				line-height: 1.5;
				letter-spacing: 0;
				padding: 1.2rem;
			}
		}
	}
}

@media #{$tbpc} {
	.c-special-list {
		width: 100%;
		-js-display: flex;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		li {
			width: 20.5%;
			margin: 0 6% 5rem 0;
			&:nth-child(4n) {
				margin-right: 0;
			}
		}
		a {
			background: #fff;
			box-sizing: border-box;
			border-radius: $radius;
			display: block;
			width: 100%;
			min-height: 22rem;
			margin: 0 auto;
			padding: 1rem;
			position: relative;
			&:hover {
				text-decoration: none;
			}
		}
		.c-special-list-item__thumb {
			background: #000;
			width: 100%;
			height: 12.5rem;
			span {
				background-position: 50% 50%;
				background-size: cover;
				display: block;
				width: 100%;
				height: 100%;
			}
		}
		.c-special-list-item__title {
			font-weight: normal;
			text-decoration: none;
			padding: 1rem 0 0;
		}
		.c-special-list-item__more {
			position: absolute;
			right: 1rem;
			bottom: 1rem;
			span {
				@include spLinksIcon;
				font-weight: normal;
				text-decoration: underline;
				color: $colorBase;
			}
		}
	}
	.c-special-list--bg {
		a {
			background: $colorBgThinner;
		}
	}
	.c-special-list__wrap {
		.c-special-list {
			width: 100%;
			-js-display: flex;
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			li {
				width: 22%;
				margin: 0 4% 5rem 0;
				border: #E5E5E5 solid 1px;
				box-sizing: border-box;
				&:nth-child(4n) {
					margin-right: 0;
				}
			}
			a {
				background: #fff;
				box-sizing: border-box;
				border-radius: $radius;
				display: block;
				width: 100%;
				margin: 0 auto;
				padding: 1rem;
				position: relative;
				&:hover {
					text-decoration: none;
					&>.c-special-list-item__title{
						color: #fb2929;
					}
				}
			}
			.c-special-list-item__thumb {
				background: #fff;
				width: 100%;
				height: 15rem;
				span {
					background-position: 50% 50%;
					background-size: contain;
					background-repeat: no-repeat;
					display: block;
					width: 100%;
					height: 100%;
					border-radius: 3px;
				}
			}
			.c-special-list-item__title {
				font-weight: normal;
				text-decoration: none;
				padding: 1rem 0 0;
			}
			.c-special-list-item__more {
				position: absolute;
				right: 1rem;
				bottom: 1rem;
				span {
					@include spLinksIcon;
					font-weight: normal;
					text-decoration: underline;
					color: $colorBase;
				}
			}
		}
	}
}