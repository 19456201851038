@charset "UTF-8";
// ==========================================================================
// Alighn
// エディタ内の位置関係の設定
// ==========================================================================
.mceContentBody,
.post {
	.aligncenter {
		display: block;
		margin: 0 auto;
	}
	.alignleft {
		float: left;
	}
	.alignright {
		float: right;
	}
	.photoleft {
		float: left;
		padding: 0 10px 10px 0;
	}
	.photoright {
		float: right;
		padding: 0 0 10px 10px;
	}
	.textcenter {
		text-align: center;
	}
}