@charset "UTF-8";
// ==========================================================================
// Magazine
// 雑誌TOP＆一覧＆詳細
// ==========================================================================

//雑誌一覧
.c-item-list--magazine {
	.p-magazines-list-item-button__item {
		a {
			@include animeBg;
			border-radius: $radius;
			background: #47ACD5;
			color: #fff;
			display: block;
			cursor: pointer;
			text-align: center;
			padding: .8rem .4rem;
			
			&:hover {
				opacity: 0.8;
				text-decoration: none;
			}
		}
	}
	
	@media #{$sp} {
		.c-item-list--magazine__item {
			padding: 0 $spSpace;
			border-bottom: #ccc solid 1px;
			margin: 0 0 2rem;
		}
		
		.c-item-inner--magazine {
			@include flow(100%);
		}
		
		.c-item-list__thumb--magazine {
			float: left;
			width: 11rem;
			padding: 0 2rem 2rem 0;
			
			img {
				width: 100%;
				height: auto;
			}
		}
		
		.c-item-list__info--magazine {
			//overflow: hidden;
		}
		
		
		.c-item-list__title--magazine {
			font-size: 1.6rem;
			font-weight: bold;
			margin: 0 0 .4rem;
		}
		
		.c-item-list__price--magazine {
			color: $colorPrice;
			margin: 0 0 .4rem;
		}
		
		.c-item-list__exp--magazine {
			font-size: 1.1rem;
			padding: 0 0 2rem;
		}
		
		.p-magazines-list-item-button-group {
			width: 100%;
			overflow: hidden;
			margin: 0 0 1.4rem;
			clear: both;
			
			.p-magazines-list-item-button__item {
				box-sizing: border-box;
				padding: 0 0 1.6rem 0;
			}
		}
	}

	@media #{$tbpc} {
		.c-item-inner--magazine {
			@include table(100%);
			padding: 0 0 4.8rem;
		}
		
		.c-item-list__thumb--magazine {
			@include tableCell(top);
			width: 20%;
			padding: 0 2rem 0 0;
			
			img {
				width: 100%;
				height: auto;
			}
		}
		
		.c-item-list__info--magazine {
			@include tableCell(top);
		}
		
		.c-item-list__title--magazine {
			font-size: 1.6rem;
			font-weight: bold;
			margin: 0 0 .4rem;
		}
		
		.c-item-list__price--magazine {
			color: $colorPrice;
			margin: 0 0 .4rem;
		}
		
		.p-magazines-list-item-button-group {
			width: 100%;
			overflow: hidden;
			margin: 1.6rem 0 0;
			
			.p-magazines-list-item-button__item {
				width: 50%;
				float: left;
				box-sizing: border-box;
				padding: 0 2rem 1.6rem 0;
			}
		}
	}
}



//雑誌のハイライト部分（雑誌TOP）
.p-magazines-latest {
	@media #{$sp} {
		margin: 0 $spSpace;
		border: $bdrThinColor solid 1px;
		
		.p-magazines-latest__thumb {
			text-align: center;
			padding: $spSpace $spSpace 0;
			margin: 0 0 1.6rem;
		}
		
		.p-magazines-latest__body {
			padding: 0 0 $spSpace;
			text-align: center;
		}
		
		.p-magazines-latest__guide {
			@include gradDate;
			border-radius: 15px;
			color: #fff;
			font-size: 1.4rem;
			max-width: 14rem;
			width: 100%;
			line-height: 30px;
			display: inline-block;
			margin: 0 auto 1.6rem;
			text-align: center;
		}
		
		.p-magazines-latest__title {
			font-size: 1.4rem;
			font-weight: bold;
			margin: 0 0 $spSpace;
		}
		
		.p-magazines-latest__info {
			text-align: left;
			padding: 0 $spSpace;
		}
		
		.p-magazines-latest__info-price {
			margin: 0 0 1rem;
		}
		
		.p-magazines-info {
			background: #f1f1f1;
			padding: $spSpace;
			
			.p-magazines-info__logo {
				text-align: center;
				padding: 0 0 1.9rem;
				
				img {
					width: 11.5rem;
					height: auto;
					margin: 0 auto;
				}
			}
			
			.post {
				background: #fff;
				margin: 0;
				padding: $spSpace;
			}
		}
	}
	
	@media #{$tbpc} {
		@include table(100%);
		table-layout: fixed;
		padding: 6rem 0 10rem;

		.p-magazines-latest__thumb {
			@include tableCell(top);
			width: 21%;
			padding: 0 2.5% 0 0;
			img{
				box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.12);
			}
		}

		.p-magazines-latest__body {
			@include tableCell(top);
		}
		
		.p-magazines-latest__guide {
			@include gradDate;
			border-radius: 15px;
			color: #fff;
			font-size: 1.4rem;
			font-weight: bold;
			max-width: 14rem;
			width: 100%;
			line-height: 30px;
			display: inline-block;
			margin: 0 auto 2.6rem;
			text-align: center;
		}
		
		.p-magazines-latest__title {
			font-size: 1.6rem;
			font-weight: bold;
			margin: 0 0 $spSpace;
		}
		
		.p-magazines-latest__info {
			text-align: left;
		}
		
		.p-magazines-latest__info-price {
			margin: 0 0 1rem;
		}

		.p-magazines-info {
			box-sizing: border-box;
			@include tableCell(top);
			background: #f1f1f1;
			width: 50%;
			padding: 2.5rem 3rem 3rem;
			
			.p-magazines-info__logo {
				padding: 1rem 0 3.6rem;
				
				img {
					width: 15rem;
					height: auto;
				}
			}
			
			.post {
				background: #fff;
				margin: 0;
				padding: 3rem 3rem 1.5rem;
			}
		}
	}
}

.p-magazines-btn{
	a{
		display: inline-block;
		width: 100%;
		margin: .6rem 0;
		padding: .7rem;
		text-align: center;
		font-weight: bold;
		color: #fff;
		background-color: #47ACD5;
		border-radius: 3px;
		box-sizing: border-box;
		&:hover{
			opacity: 0.8;
			text-decoration: none;
		}
	}
	&-group{
		@media #{$tbpc}{
			padding: 1rem 2rem 0 0;
		}
	}
}