@charset "UTF-8";
// ==========================================================================
// Item List
// 商品一覧のパーツ
// ==========================================================================

// 通常のアイテムリスト
.c-item-list {
	background: #fff;
	border-top: $colorBdr solid 1px;
	
	@media #{$tbpc} {
		-js-display: flex;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content: space-around;
		width: 100%;
		border-top: none;
	}
	
	.c-item-list__item {
		border-bottom: $colorBdr solid 1px;
		
		@media #{$sp} {
			position: relative;
		}
		
		@media #{$tbpc} {
			border-bottom: none;
			flex-direction: row;
			list-style: none;
			padding: 0 0 4rem;
			width: 20%;
			//width: 22rem;
		}
		
		a {
			box-sizing: border-box;
			//color: $colorBase;
			display: block;
			padding: $spSpace 4.5rem 1.6rem $spSpace;
			
			@media #{$tbpc} {
				padding: 0;
				cursor: default;
			}
			
			&:hover {
				text-decoration: none;
			}
		}
	}
	
	&.c-item-list--fouth {
		.c-item-list__item {
			@media #{$tbpc} {
				width: 25%;
			}
		}
	}
}

.c-item-inner {
	@include table(100%);
	table-layout: fixed;
	
	@media #{$tbpc} {
		box-sizing: border-box;
		display: block;
	}
	
	.c-item-inner__thumb{
		@include tableCell(top);
		width: 6.5rem;
		padding: 0 1.2rem 0 0;
		position: relative;
		
		@media #{$sp} {
			@include tableCell(middle);
		}
		
		@media #{$tbpc} {
			display: table-cell;
			vertical-align: bottom;
			width: 100%;
			height: 17.4rem;
			padding: 0 0 2rem 1rem;
		}
		
		img {
			box-shadow:1px 1px 6px 1px rgba(0,0,0,0.12);
			
			@media #{$sp} {
				width: 100%;
				//height: 9rem;
			}
			
			@media #{$tbpc} {
				cursor: pointer;
				width: auto;
				max-height: 100%;
				max-width: 16rem;
			}
		}
	}
	
	.c-item-inner__info {
		@include tableCell(top);
		
		@media #{$tbpc} {
			display: block;
			box-sizing: border-box;
			padding: 0 2rem 0 0;
		}
	}
}

//Newアイコン
.c-item-inner__new {
	background: url(images/icon_item_new@2x.png) no-repeat scroll 0 0;
	
	@media #{$sp} {
		background-size: 33px 28px;
		display: block;
		width: 3.3rem;
		height: 2.8rem;
		position: absolute;
		//bottom: -.3rem;
		//left: -.3rem;
		margin: -2.3rem -.4rem;
	}
	@media #{$tbpc} {
		background-size: 47px 40px;
		display: block;
		width: 4.7rem;
		height: 4rem;
		position: absolute;
		bottom: 1.6rem;
		left: .5rem;
	}
}

// カテゴリの振り分け
//@media #{$sp} {
//	.c-item-list__cat {
//		border-radius: 1.25rem;
//		width: 2.5rem;
//		height: 2.5rem;
//		position: absolute;
//		top: 1.4rem;
//		right: 1.5rem;
//		
//		span {
//			display: block;
//			width: 100%;
//			height: 100%;
//		}
//	}
//	
//	@each $catItem in $arrayCat{
//		$indexKey: index($arrayCat, $catItem);
//		.c-item-list__cat.#{$catItem} {
//			background: nth($arrayCatColor, $indexKey);
//			
//			@if $catItem == "magazine" {
//				@include magazineGrd;
//			} @else if $catItem == "books" {
//				@include booksGrd;
//			} @else if $catItem == "music_score" {
//				@include musicScoreGrd;
//			} @else if $catItem == "music_manual" {
//				@include musicManualGrd;
//			} @else if $catItem == "cd_dvd_othens" {
//				@include CdDvdOthersGrd;
//			}
//		}
//	}
//	
//	@each $name in $arrayCat{
//		.c-item-list__cat.#{$name} {
//			span {
//				background: url(images/icon_cat_#{$name}@2x.png) no-repeat scroll 50% 50%;
//				background-size: 100% auto;
//			}
//		}
//	}
//}
//@media #{$tbpc} {
//	.c-item-inner__cat {
//		border-radius: 1.75rem;
//		width: 3.5rem;
//		height: 3.5rem;
//		position: absolute;
//		bottom: .3rem;
//		left: 0;
//		
//		span {
//			display: block;
//			width: 100%;
//			height: 100%;
//		}
//	}
//	
//	@each $catItem in $arrayCat{
//		$indexKey: index($arrayCat, $catItem);
//		.c-item-inner__cat.#{$catItem} {
//			background: nth($arrayCatColor, $indexKey);
//			
//			@if $catItem == "magazine" {
//				@include magazineGrd;
//			} @else if $catItem == "books" {
//				@include booksGrd;
//			} @else if $catItem == "music_score" {
//				@include musicScoreGrd;
//			} @else if $catItem == "music_manual" {
//				@include musicManualGrd;
//			} @else if $catItem == "cd_dvd_othens" {
//				@include CdDvdOthersGrd;
//			}
//		}
//	}
//	
//	@each $name in $arrayCat{
//		.c-item-inner__cat.#{$name} {
//			span {
//				background: url(images/icon_cat_#{$name}@2x.png) no-repeat scroll 50% 50%;
//				background-size: 100% auto;
//			}
//		}
//	}
//}

//細かい箇所
.c-item-info__title {
	font-weight: bold;
	margin: 0 0 0.6rem;
	
	@media #{$tbpc} {
		//color: $colorBaseThin;
		font-weight: normal;
		cursor: pointer;
		
		&:hover {
			text-decoration: underline;
		}
	}
}

.c-item-info__sub {
	color: $colorBaseThinner;
	font-size: 1.1rem;
	margin: 0 0 1rem;
}

.c-item-info__price {
	color: $colorPrice;
	font-weight: bold;
}


.c-item-info__release-date {
	color: $colorBase;
	font-size: 1.3rem;
	font-weight: bold;
	margin: 0.8rem 0 0;

	@media #{$tbpc} {
		text-align: center;
	}
}



// ホームで使用する
@media #{$sp} {
	.c-item-list__item--home {
		border-bottom: $bdrThinColor solid 1px;
		
		&:last-child {
			border-bottom: none;
		}

		a {
			padding: $spSpace 0;
			display: block;
			//color: $colorBase;
		}

		.c-item-inner--home {
			@include table(100%);
			table-layout: fixed;
		}

		.c-item-inner__thumb--home {
			@include tableCell(top);
			width: 6.5rem;
			padding: 0 1rem 0 0;

			img {
				width: 100%;
				height: auto;
				box-shadow:1px 1px 6px 1px rgba(0,0,0,0.22);
			}
		}

		.c-item-inner__info--home {
			@include tableCell(top);
			
			.c-item-info__title--home {
				font-size: 1.4rem;
				color: $colorBase;
			}
		}
	}
	
	.c-item-info__date--home {
		@include gradDate;
		border-radius: 30px;
		width: 10.8rem;
		display: inline-block;
		color: #fff;
		font-size: .9rem;
		text-align: center;
		padding: .3rem 0 .2rem;
		margin: 0 0 1rem;
		line-height: 1.4;
	}
}
@media #{$tbpc} {
	.c-item-list--home {
		width: 100%;
		letter-spacing: -.40em;
	}
	.c-item-list__item--home {
		display: inline-block;
		vertical-align: top;
		//width: 12.2rem;
		width: 11%;
		padding: 0 11% 3rem 0;
		
		&:nth-child(5n) {
			padding: 0 0 3rem 0;
		}
		
		a {
			//color: $colorBase;
		}
		
	}
	
	.c-item-inner--home {
		width: 12.2rem;
		letter-spacing: 0;
	}
	
	.c-item-inner__date--home {
		@include gradDate;
		border-radius: 30px;
		width: 100%;
		display: inline-block;
		color: #fff;
		font-size: 1.2rem;
		font-weight: bold;
		text-align: center;
		line-height: 1;
		padding: .4rem 0;
		margin: 0 0 1.1rem;
	}
	
	.c-item-inner__thumb--home {
		margin: 0 0 1rem;
		
		img {
			width: 100%;
			height: auto;
			box-shadow:1px 1px 6px 1px rgba(0,0,0,0.22);
		}
	}
	
	.c-item-info__title--home {
		//color: $colorBaseThin;
	}
}

//flexboxが使えないie9などのブラウザ用（そもそもflexbox使わないか検討中）
@media #{$tbpc} {
	.no-flexwrap {
		.c-item-list {
			display: block;
			letter-spacing: -.40em;
			
			.c-item-list__item {
				display: inline-block;
				vertical-align: top;
				
				.c-item-inner {
					letter-spacing: 0;
				}
			}
		}
	}
}
