@charset "UTF-8";
// ==========================================================================
// Calendar
// 商品カレンダー
// ==========================================================================

//サイドカラム
@media #{$sp} {
	.p-cal__side {
		.p-cal-side__title {
			position: relative;
			
			&:after {
				background: url(images/icon_open_close_sp@2x.png) no-repeat scroll 0 bottom;
				background-size: 15px 30px;
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 1rem;
				width: 1.5rem;
				height: 1.5rem;
				margin: auto 0;
			}
			
			&.p-cal-side__title--active {
				&:after {
					background: url(images/icon_open_close_sp@2x.png) no-repeat scroll 0 top;
					background-size: 15px 30px;
				}
			}
		}
	}
	
	.p-cal__archive {
		display: none;
	}
}

//.side-fixed.p-cal-side-fixed {
//	@media #{$sp} {
//		
//		.p-cal-side__title {
//			position: relative;
//			
//			&:after {
//				background: url(images/icon_open_close_sp@2x.png) no-repeat scroll 0 bottom;
//				background-size: 15px 30px;
//				content: '';
//				display: block;
//				position: absolute;
//				top: 0;
//				bottom: 0;
//				right: 1rem;
//				width: 1.5rem;
//				height: 1.5rem;
//				margin: auto 0;
//			}
//			
//			&.p-cal-side__title--active {
//				&:after {
//					background: url(images/icon_open_close_sp@2x.png) no-repeat scroll 0 top;
//					background-size: 15px 30px;
//				}
//			}
//		}
//		
//		.p-cal-archive__title {
//			background: #e5e5e5;
//			padding: .5rem 0 .3rem $spSpace;
//			font-weight: bold;
//		}
//		
//		.p-cal-archive {
//			display: none;
//		}
//	}
//	
//	@media #{$tbpc} {
//		border-bottom: 4px solid #ddd;
//		border-radius: 0 0 $radius $radius;
//		
//		.p-cal-side__title {
//			text-align: center;
//			cursor: default;
//		}
//		
//		.p-cal-archive__title {
//			border-right: 4px solid #ddd;
//			border-left: 4px solid #ddd;
//			padding: 1.5rem 2rem .8rem 2.8rem;
//			font-weight: bold;
//			font-size: 1.6rem;
//		}
//
//		.p-cal-archive__links {
//			border-bottom: none;
//			border-radius: 0;
//			margin: 0;
//			
//		}
//		
//		.p-cal-archive-links__item {
//			padding-left: 2.8rem;
//			
//			a {
//				background: none;
//				display: inline-block;
//				padding: 0;
//				margin: 0 0 1rem;
//				font-size: 1.4rem;
//			}
//			&:after {
//				background: none;
//			}
//		}
//	}
//}


.p-cal {
	display: block;
	margin: 0 $spSpace;
	border-top: $colorBdrThin solid 1px;

		
	.p-cal__date {
		width: 100%;
		box-sizing: border-box;
		border-left: $colorBdrThin solid 1px;
		border-right: $colorBdrThin solid 1px;
		background: #f0f0f0;
		font-size: 1.5rem;
		font-weight: bold;
		display: block;
		padding: 1.1rem 1.5rem;
		text-align: left;

		span {
			font-weight: normal;
			font-size: 1.2rem;
		}
	}
	
	.p-cal-date__box {
		border-left: $colorBdrThin solid 1px;
		border-right: $colorBdrThin solid 1px;
		display: block;
		width: 100%;
		box-sizing: border-box;

		.c-item-list {
			.c-item-inner {
			}
			.c-item-inner__info {
				.c-item-info__date {
					display: none;
				}
			}
		}
	}
	
	@media #{$tbpc} {
		margin: 0;
		
		.p-cal__date {
			font-size: 1.8rem;
			padding: 1rem 0 .9rem 2rem;

			span {
				font-size: 1.6rem;
			}
		}
		
		.p-cal-date__box {
			.c-item-list {
				.c-item-list__item {
					width: 100%;
					box-sizing: border-box;
					//border-right: #E6E6E6 solid 1px;
					border-bottom: $colorBdrThin solid 1px;
					padding: 2.8rem 2.8rem 3.2rem;
				}
				
				.c-item-inner {
					@include table(100%);
					box-sizing: border-box;
					table-layout: auto;
					position: relative;
				}
				.c-item-inner__thumb {
					@include tableCell(middle);
					padding: 0 1.5rem 0 0;
					width: 12%;
					height: 100%;
					position: static;
					
					img {
						width: 100%;
						height: auto;
						max-height: 100%;
					}
					
					.c-item-inner__cat {
						top: 0;
						right: 0;
						bottom: inherit;
						left: inherit;
					}
					
					.c-item-inner__new{
						bottom: 0;
						left: 0;
					}
				}
				.c-item-inner__info {
					@include tableCell(top);
					padding: .8rem 6rem 0 0;
					box-sizing: border-box;
				}
			}
		}
	}
}



