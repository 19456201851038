@charset "UTF-8";
// ==========================================================================
// Filter
// 絞り込みのパーツ
// ==========================================================================

//ボタンタップ時に出る黒の背景
.c-filter-overlay {
	background: rgba(0,0,0,0);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	transition: background 0.1s ease-in-out;
	
	&--display {
		background: rgba(0,0,0,0.8);
		z-index: 4999;
	}
	
	@media #{$tbpc} {
		display: none;
	}
}

.c-filter {
	@media #{$sp} {
		position: fixed;
		top: 0;
		left: 100%;
		width: 100 - 12%;
		height: 100%;
		margin: 0;
		//z-index: -1;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		transition: left 0.3s ease-out;
	}
	
	&--display {
		@media #{$sp} {
			left: 12%;
			width: 100 - 12%;
			z-index: 5000;
			margin: 0;
		}
	}
	
	@media #{$tbpc} {
		display: block;
		padding: 0;
		margin: 0 0 2.8rem;
	}
	
	.c-filter__head {
		background: $colorBaseThin;
		width: 100%;
		display: table;
		table-layout: fixed;
		padding: 1rem 0;
		position: relative;
		
		&-title {
			color: #fff;
			display: table-cell;
			vertical-align: middle;
			font-size: 1.4rem;
			font-weight: bold;
			padding: 0 0 0 1.5rem;
		}
		
		&-button {
			width: 7.3rem;
			display: table-cell;
			vertical-align: middle;
			text-align: right;
			padding: 0 .8rem 0 0;
			
			a {
				background: $colorBase;
				border-radius: $radius;
				font-size: 1.2rem;
				font-weight: bold;
				text-align: center;
				color: #fff;
				display: block;
				padding: 1.2rem 0;
			}
		}
		
		
		@media #{$tbpc} {
			display: none;
		}
	}
}

.c-filter-box {
	position: relative;
	
	@media #{$tbpc} {
		background: none;
		padding: 0;
	}
	
	.c-filter-box__title {
		background: $colorBase;
		color: #fff;
		padding: 1.5rem 0 1.4rem 1.4rem;
		
		@media #{$tbpc} {
			border-radius: $radius $radius 0 0;
			box-sizing: border-box;
			background-image: linear-gradient(-180deg, #2C4273 0%, #1A2453 100%);
			border: 1px solid #024B74;
			font-weight: bold;
			font-size: 1.6rem;
			text-align: center;
			margin: 0;
			padding: 1.2rem 0;
		}
	}
	
	@media #{$sp} {
		.c-filter-box__item {
			a {
				display: block;
				
				p {
					background: url(images/icon_links_sp.png) no-repeat scroll right 50%;
					background-size: 5px 8px;
				}
			}
		}
		
		//親カテ
		.c-filter-box__item--parent {
			.c-filter-box__item---parent{
				background: #fff;
				border-bottom: #ddd solid 1px;
				padding: 1.4rem 1.6rem 1.2rem 1.4rem;
				
				p {
					color: $colorBase;
					font-weight: bold;
					font-size: 1.6rem;
				}
			}
		}
		
		//子カテ
		.c-filter-box__children {
			a {
				background: $colorBgThin;
				border-bottom: #ddd solid 1px;
				padding: 1.5rem 1.6rem 1.4rem 1.4rem;
				
				p {
					color: $colorBase;
					font-weight: bold;
				}
			}
			
			.children {
				p {
					font-weight: normal;
				}
			}
		}
	}
	
	@media #{$tbpc} {
		.c-filter-box__body {
			border-top: #E6E6E6 solid 1px;
			border-right: #E6E6E6 solid 5px;
			border-bottom: #E6E6E6 solid 5px;
			border-left: #E6E6E6 solid 5px;
			border-radius: 0 0 $radius $radius;
			box-sizing: border-box;
			padding: 2.4rem 1.5rem 0;
		}
		
		.c-filter-box__item {
			margin: 0 0 1.8rem;
			border-bottom: #9B9B9B solid 1px;
			
			&:last-child {
				border-bottom: none;
			}
			
			a {
				color: $colorBase;
				display: inline-block;
				
				p {
					color: $colorBase;
				}
			}
			
			//親カテ
			.c-filter-box__item---parent {
				background: url(images/icon_filter_links@2x.png) no-repeat scroll 0 50%;
				background-size: 6px 6px;
				font-size: 1.4rem;
				font-weight: bold;
				padding: 0 0 0 1.2rem;
				margin: 0 0 .8rem;
			}
			
			//子カテ
			.c-filter-box__children {
				padding: 0 0 0 1.2rem;
				
				& > li {
					padding: 0 0 0 .2rem;
					
					a {
						padding: 0 0 .8rem;
						font-size: 1.2rem;
						font-weight: bold;
					}
				}
				
			}
			
			//孫カテ
			.children {
				li {
					padding: 0 0 0 1.1rem;
					
					a {
						font-weight: normal;
					}
				}
			}
		}
		
		
	}
	
	
}

/*
		a {
			background: #fff;
			border-bottom: $bdrColor solid 1px;
			box-sizing: border-box;
			color: $colorBase;
			display: block;
			padding: 1.6rem 1.3rem 1.6rem 1.5rem;
			
			@media #{$tbpc} {
				font-weight: bold;
				font-size: 1.6rem;
				padding: 1rem;
			}
			
			p {
				background: url(images/sp_icon_link.png) no-repeat scroll right 50%;
				background-size: 9px 14px;
				display: block;
				
				@media #{$tbpc} {
					background: none;
				}
			}
			
		}


.c-filter-box__children li a {
	@media #{$tbpc} {
		background: #fafafa;
		font-size: 1.2rem;
	}
}

.c-filter-box__children li .children li a {
	@media #{$tbpc} {
		font-weight: normal;
		padding: 0.7rem 1rem;
	}
}
*/