@charset "UTF-8";
// ==========================================================================
// Info List
// 新着情報一覧やお詫び一覧の一覧パーツ
// ==========================================================================

.c-info-list {
	
	@media #{$sp} {
		border-top: $colorBdr solid 1px;
	}
	
	&__item {
		border-bottom: $colorBdr solid 1px;
		
		@media #{$tbpc} {
			border: none;
			padding: 0 0 1.2rem;
			margin: 0;
		}
	}
}

.c-info-item {
	overflow: hidden;
	width: 100%;
	
	@media #{$sp} {
		box-sizing: border-box;
		padding: $spSpace;
	}
	
	@media #{$tbpc} {
		display: table;
	}
	
	&__date {
		font-weight: bold;
		color: $colorBase;
		float: left;
		padding: 0.2rem 1rem 0 0;
		word-wrap: break-word;
		
		@media #{$tbpc} {
			float: none;
			display: table-cell;
			vertical-align: middle;
			padding: 0 2rem 0 0;
		}
	}
	
	&__cat {
		@media #{$tbpc} {
			float: none;
			display: table-cell;
			vertical-align: middle;
			word-wrap: break-word;
		}
		
		span {
			border-radius: $radius;
			background: #e5e5e5;
			display: inline-block;
			text-align: center;
			font-weight: bold;
			font-size: 1.1rem;
			padding: 0.3rem 0;
			margin: 0 2rem 0 0;
			width: 85px;
			
			@media #{$tbpc} {
				padding: 0.5rem 0;
			}
		}
	}
	
	&__title {
		padding: .8rem 0 0;
		clear: both;
		width: 100%;
		
		@media #{$tbpc} {
			display: table-cell;
			padding: 0;
			vertical-align: middle;
		}
	}
}