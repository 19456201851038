@charset "UTF-8";
// ==========================================================================
// feature
// 特集周りのページの設定
// ==========================================================================
.s-feature-level {
	width: 100%;
	margin: auto;
	.hero img {
		width: 100%;
	}
	.gakufu-wrap {
		padding: 2em 0;
		h2 {
			padding-bottom: 0;
			margin: 3rem 0 0;
			&:before {
				display: none;
			}
		}
		.level {
			&-01 {
				color: #FF6490;
			}
			&-02 {
				color: #31A500;
			}
			&-03 {
				color: #528CFF;
			}
			&-04 {
				color: #FF9D00;
			}
		}
	}
	.column.column2 {
		display: table;
		width: 100%;
		margin: 2em 0;
	}
	.column.column2 li {
		width: auto;
		display: table-cell;
		vertical-align: top;
		padding-left: 2em;
	}
	.column.column2 li:first-child {
		width: 165px;
		padding-left: 0;
		text-align: center;
	}
	.item-info dl {
		display: table;
		margin-bottom: 0;
		width: 100%;
		border-top: 1px dotted #ccc;
		padding: 1rem 0 0.3rem;
	}
	.item-info dl:last-child {
		border-bottom: 1px dotted #ccc;
	}
	.item-info dt,
	.item-info dd {
		display: table-cell;
		vertical-align: middle;
		width: auto;
	}
	.item-info dt {
		width: 20%;
	}
	.relation h3 {
		position: relative;
		background-color: #fff;
		margin: 0;
		padding: 0;
		display: inline-block;
		width: auto;
		padding: .5em 2em 0;
		border: 1px solid #ccc;
		border-bottom: none;
		margin-bottom: -1px;
		border-radius: 8px 8px 0 0;
	}
	.relation .item-list {
		display: table;
		width: 100%;
		background-color: #fff;
		border: 1px solid #ccc;
		padding: 0 1em;
		box-sizing: border-box;
	}
	.relation .item-list > li {
		display: table-cell;
		width: 33%;
		vertical-align: top;
		padding: 0 .5em;
		box-sizing: border-box;
	}
	.relation .item-list li .column2 li {
		padding-left: 1em;
	}
	.relation .item-list li .column2 li:first-child {
		width: 60px;
		text-align: center;
		padding-left: 0;
	}
	@media screen and (max-width: 767px) {
		.column.column2,
		.column.column2 li:first-child,
		.column.column2 li,
		.relation .item-list li .column2 li:first-child {
			display: block;
			width: 100%;
			padding-left: 0;
		}
		.relation .item-list li .column2 li {
			padding-left: 0;
		}
		.relation .item-list li .column2 li:first-child {
			margin-bottom: 1em;
		}
		.item-info {
			margin-top: 2em;
		}
	}
}

.s-feature-raphael {
	width: 100%;
	margin: auto;
	.hero img {
		width: 100%;
		margin-bottom: 2em;
	}
	.column.column2 {
		display: table;
		width: 100%;
		margin: 2em 0;
	}
	.column.column2 li {
		width: auto;
		display: table-cell;
		vertical-align: top;
		padding-left: 2em;
	}
	.column.column2 li:first-child {
		width: 200px;
		padding-left: 0;
		text-align: center;
	}
	.buy {
		display: block;
		background-color: #1f3a61;
		color: #fff;
		font-weight: bold;
		padding: .5em 2em;
		text-align: center;
		box-sizing: border-box;
	}
	.item-title {
		color: #414F73;
		margin: 0 0 1rem;
		font-size: 2rem;
	}
	.item-text {
		color: #414F73;
		font-size: 1.1rem;
	}
	.note p {
		border-bottom: 1px dashed #ccc;
		padding: .8em 0;
		margin: 0;
	}
	@media screen and (max-width: 767px) {
		.column.column2,
		.column.column2 li:first-child,
		.column.column2 li {
			display: block;
			width: 100%;
			padding-left: 0;
		}
		.buy {
			display: block;
			width: 100%;
		}
	}
}

.s-feature-nightmare {
	width: 100%;
	margin: auto;
	.hero img {
		width: 100%;
		margin-bottom: 1.5rem;
	}
	.nightmare-wrap {
		padding: 2em 0;
	}
	.column.column2 {
		display: table;
		width: 100%;
		margin: 2em 0;
	}
	.column.column2 li {
		width: auto;
		display: table-cell;
		vertical-align: top;
		padding-left: 2em;
	}
	.column.column2 li:first-child {
		width: 120px;
		padding-left: 0;
		text-align: center;
	}
	.buy {
		display: block;
		margin-top: 1rem;
		background-color: #5c5a5a;
		color: #fff;
		font-weight: bold;
		padding: .5em 2em;
		text-align: center;
		box-sizing: border-box;
	}
	.attention {
		//color: #99FF00;
	}
	@media screen and (max-width: 767px) {
		.column.column2,
		.column.column2 li:first-child,
		.column.column2 li {
			display: block;
			width: 100%;
			padding-left: 0;
		}
		.buy {
			display: block;
			width: 100%;
		}
		.item-title {
			text-align: center;
		}
	}
}

.s-feature-kohhasebe {
	width: 100%;
	margin: auto;
	.hero img {
		width: 100%;
		margin-bottom: 1.5rem;
	}
	.section-title {
		background-color: #dd0008;
		color: #ffd293;
		font-size: 1.5rem;
		padding: 0.5rem 0;
	}
	.column.column2 {
		display: table;
		width: 100%;
		margin: 2em 0;
	}
	.column.column2 > li {
		width: auto;
		display: table-cell;
		vertical-align: top;
		padding-left: 0;
	}
	.column.column2 > li:last-child {
		width: 200px;
		padding-left: 2em;
		text-align: center;
	}
	.column.column2.reverse li:last-child {
		text-align: left;
		width: auto;
	}
	.column.column2.reverse li:first-child {
		width: 200px;
		text-align: center;
	}
	.buy {
		display: block;
		margin-top: 1rem;
		background-color: #dd0008;
		color: #fff;
		font-weight: bold;
		padding: .5em 2em;
		text-align: center;
		box-sizing: border-box;
	}
	.item-title {
		color: #dd0008;
		margin: 0 0 1rem;
	}
	@media screen and (max-width: 767px) {
		.column.column2,
		.column.column2 li:last-child,
		.column.column2 li,
		.column.column2.reverse li:first-child {
			display: block;
			width: 100%;
			padding-left: 0;
		}
		.buy {
			display: block;
			width: 100%;
		}
	}
}

.s-feature-wowow {
	width: 100%;
	margin: auto;
	.hero img {
		width: 100%;
	}
	.section-title {
		background-color: #0055a6;
		color: #fff;
		font-size: 1.8rem;
		padding: 0.5rem 1rem;
		&:before {
			display: none;
		}
	}
	h3 {
		margin: 0 0 3rem;
		font-weight: bold;
	}
	.column.column2 {
		display: table;
		width: 100%;
		margin: 2em 0;
	}
	.column.column2 > li {
		width: auto;
		display: table-cell;
		vertical-align: top;
		padding-left: 0;
	}
	.column.column2 > li:last-child {
		width: 120px;
		padding-left: 2em;
		text-align: center;
	}
	@media screen and (max-width: 767px) {
		.column.column2,
		.column.column2 li:last-child,
		.column.column2 li {
			display: block;
			width: 100%;
			padding-left: 0;
		}
	}
}

.s-feature-netsuretsugakkisai {
	width: 740px;
	margin: auto;
	@media #{$sp} {
		width: 100%;
	}
	.hero img {
		width: 100%;
	}
	.section-title {
		padding-left: 1rem;
		padding-bottom: 0;
		background-color: #dc5622;
		font-size: 1.8rem;
		&:before {
			display: none;
		}
		span {
			padding: 0 2rem;
			display: inline-block;
			background-color: #fff;
		}
	}
	.column.column2 {
		display: table;
		width: 100%;
		margin: 2em 0;
	}
	.column.column2 > li {
		width: auto;
		display: table-cell;
		vertical-align: top;
		padding-left: 0;
	}
	.column.column2 > li:last-child {
		width: 120px;
		padding-left: 2em;
		text-align: center;
	}
	.artist-list {
		max-width: 680px;
		margin: 0 auto;
	}
	.artist-list img {
		display: inline;
		max-width: 162px;
		margin: 0 0 5px 5px;
	}
	.bord {
		width: auto;
		border-spacing: 0;
		font-size: 12px;
	}
	.bord th {
		color: #000;
		padding: 8px 15px;
		background: #eee;
		background: -moz-linear-gradient(#eee, #ddd 50%);
		background: -webkit-gradient(linear, 100% 0%, 100% 50%, from(#eee), to(#ddd));
		font-weight: bold;
		border-top: 1px solid #aaa;
		border-bottom: 1px solid #aaa;
		line-height: 120%;
		text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.9);
		box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.3) inset;
	}
	.bord th:first-child {
		border-left: 1px solid #aaa;
		border-radius: 5px 0 0 0;
	}
	.bord th:last-child {
		border-radius: 0 5px 0 0;
		border-right: 1px solid #aaa;
	}
	.bord tr td {
		padding: 8px 15px;
	}
	.bord tr td:first-child {
		border-left: 1px solid #aaa;
	}
	.bord tr td:last-child {
		border-right: 1px solid #aaa;
	}
	.bord tr {
		background: #fff;
	}
	.bord tr:nth-child(2n+1) {
		background: #f5f5f5;
	}
	.bord tr:last-child td {
		border-bottom: 1px solid #aaa;
	}
	.bord tr:last-child td:first-child {
		border-radius: 0 0 0 5px;
	}
	.bord tr:last-child td:last-child {
		border-radius: 0 0 5px 0;
	}
	@media screen and (max-width: 767px) {
		.column.column2,
		.column.column2 li:last-child,
		.column.column2 li {
			display: block;
			width: 100%;
			padding-left: 0;
		}
	}
}

.s-feature-kikuchihideki {
	width: 100%;
	margin: auto;
	.hero img {
		width: 100%;
		margin-bottom: 2em;
	}
	.column.column2 {
		display: table;
		width: 100%;
		margin: 2em 0;
	}
	.column.column2 li {
		width: auto;
		display: table-cell;
		vertical-align: top;
		padding-left: 2em;
		img {
			width: 100%;
			padding-bottom: 1rem;
		}
	}
	.column.column2 li:first-child {
		width: 200px;
		padding-left: 0;
		text-align: center;
	}
	.column.column2.reverse li {
		text-align: left;
	}
	.column.column2.reverse li:first-child {
		width: 70%;
	}
	.buy {
		display: block;
		background-color: #b81c22;
		color: #fff;
		font-weight: bold;
		padding: .5em 2em;
		text-align: center;
		box-sizing: border-box;
	}
	h2 {
		margin: 0;
		padding-bottom: 2rem;
		font-size: 1.6rem;
		&:before {
			display: none;
		}
	}
	.item-title {
		color: #cc1c22;
		font-size: 1.6rem;
		padding-bottom: 0.3rem;
	}
	.item-text {
		font-size: 1.1rem;
	}
	@media screen and (max-width: 767px) {
		.column.column2,
		.column.column2 li:first-child,
		.column.column2 li,
		.column.column2.reverse li:first-child,
		.column.column2.reverse li {
			display: block;
			width: 100%;
			padding-left: 0;
		}
		.buy {
			display: block;
			width: 100%;
		}
	}
}

.s-feature-ozzy-ozbourne {
	width: 100%;
	margin: auto;
	.hero img {
		width: 100%;
		margin-bottom: 2em;
	}
	.column.column2 {
		display: table;
		width: 100%;
		margin: 2em 0;
	}
	.column.column2 li {
		width: auto;
		display: table-cell;
		vertical-align: top;
		padding-left: 2em;
	}
	.column.column2 li:first-child {
		width: 200px;
		padding-left: 0;
		text-align: center;
	}
	.buy {
		background-color: #6d6d6d;
		color: #fff;
		font-weight: bold;
		padding: .5em 2em;
		text-align: center;
		box-sizing: border-box;
	}
	.item-title {
		color: #B63103;
		font-size: 1.8rem;
		margin: 0;
		padding-bottom: 0.5rem;
		&:before {
			display: none;
		}
	}
	.item-text {
		font-size: 1.1rem;
	}
	@media screen and (max-width: 767px) {
		.column.column2,
		.column.column2 li:first-child,
		.column.column2 li {
			display: block;
			width: 100%;
			padding-left: 0;
		}
		.buy {
			display: block;
			width: 100%;
		}
	}
}

.s-feature-hoiku {
	width: 100%;
	margin: auto;
	.hero img {
		width: 100%;
		margin-bottom: 2em;
	}
	.point {
		margin: 0 auto;
		overflow: hidden;
	}
	.point li {
		width: 25%;
		float: left;
		text-align: center;
		padding-right: 9px;
		box-sizing: border-box;
	}
	.point li:last-child {
		padding-right: 0;
	}
	.section-title {
		background-color: #2883c5;
		border-radius: 9999em;
		color: #fff;
		margin: 0;
		padding: 0.5rem 1.5rem;
		font-size: 1.5rem;
	}
	h2:before {
		display: none;
	}
	.column.column2 {
		display: table;
		width: 100%;
		margin: 2em 0;
	}
	.column.column2 li {
		width: auto;
		display: table-cell;
		vertical-align: top;
		padding-left: 2em;
	}
	.column.column2 li:first-child {
		width: 200px;
		padding-left: 0;
		text-align: center;
	}
	.column.column2.equivalent li {
		width: 45%;
		margin: 0 2.5%;
		padding: 0 2.5%;
	}
	.column.column2.equivalent li:first-child {
		margin-left: 0;
	}
	.column.column2.equivalent li:last-child {
		margin-right: 0;
	}
	.buy {
		background-color: #ec6ca6;
		color: #fff;
		font-weight: bold;
		padding: .5em 2em;
		display: block;
		text-align: center;
		box-sizing: border-box;
	}
	.item-title {
		color: #2883c5;
		font-size: 1.8rem;
		margin: 0;
		padding-bottom: 0.5rem;
		&:before {
			display: none;
		}
	}
	.item-text {
		font-size: 1.1rem;
	}
	.hoiku-detail dl {
		margin-bottom: 1em;
	}
	.hoiku-detail dt {
		color: #E15B00;
	}
	@media screen and (max-width: 767px) {
		.point li {
			width: 46%;
			margin: 0 4% 4% 0;
		}
		.point li:nth-of-type(even) {
			margin-right: 0;
		}
		.column.column2,
		.column.column2 li:first-child,
		.column.column2 li,
		.column.column2.reverse li:first-child,
		.column.column2.reverse li,
		.column.column2.equivalent li {
			display: block;
			width: 100%;
			padding-left: 0;
		}
		.buy {
			display: block;
			width: 100%;
		}
	}
}

.s-feature-bucktick {
	width: 100%;
	margin: auto;
	.hero img {
		width: 100%;
		margin-bottom: 2em;
	}
	.column.column2 {
		display: table;
		width: 100%;
		margin: 2em 0;
	}
	.column.column2 li {
		width: auto;
		display: table-cell;
		vertical-align: top;
		padding-left: 2em;
		img {
			display: block;
		}
	}
	.column.column2 li:first-child {
		width: 200px;
		padding-left: 0;
		text-align: center;
	}
	.buy {
		display: block;
		background-color: #A5884D;
		color: #fff;
		font-weight: bold;
		padding: .2em 2em;
		text-align: center;
		box-sizing: border-box;
	}
	.item-title {
		color: #A5884D;
		font-size: 1.8rem;
		margin: 0;
		padding-bottom: 0.5rem;
		&:before {
			display: none;
		}
	}
	.item-text {
		font-size: 1.1rem;
	}
	@media screen and (max-width: 767px) {
		.column.column2,
		.column.column2 li:first-child,
		.column.column2 li,
		.column.column2.reverse li:first-child,
		.column.column2.reverse li {
			display: block;
			width: 100%;
			padding-left: 0;
		}
		.buy {
			display: block;
			width: 100%;
		}
	}
}

.s-feature-nouryoku {
	width: 100%;
	margin: auto;
	.hero img {
		width: 100%;
	}
	.section {
		padding: 2em 1.5em;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 20px;
		margin: 2rem 0;
	}
	.item-title {
		font-size: 2rem;
	}
	.column.column2 {
		display: table;
		width: 100%;
		margin: 2em 0;
	}
	.column.column2 > li {
		width: auto;
		display: table-cell;
		vertical-align: top;
		padding-left: 2em;
	}
	.column.column2 > li:first-child {
		width: 120px;
		padding-left: 0;
		text-align: center;
	}
	.column.column2 > li:last-child ul {
		border-top: 2px solid #019fee;
		border-bottom: 2px solid #019fee;
		padding: 1px 0;
	}
	.column.column2 > li ul li {
		box-sizing: border-box;
	}
	.column.column2 > li:last-child ul li {
		border-top: 1px solid #019fee;
		padding: 1em 1em 0;
		position: relative;
	}
	.column.column2 > li:last-child ul li .option {
		position: absolute;
		top: 1em;
		right: 1em;
	}
	.column.column2 > li:last-child ul li:last-child {
		border-bottom: 1px solid #019fee;
	}
	.section-title {
		background-color: #3399ff;
		color: #fff;
		font-size: 1.8rem;
		padding: 0.5rem 1rem;
		&:before {
			display: none;
		}
	}
	.section-inner {
		padding: 0 1em;
	}
	.block-title {
		color: #3399ff;
		font-weight: bold;
		padding-bottom: .2em;
		border-bottom: 1px solid #3399ff;
	}
	.download span {
		vertical-align: middle;
		line-height: 32px;
	}
	.download img {
		padding-right: 1em;
	}
	.download a {
		padding: 0 0 0 1em;
	}
	@media screen and (max-width: 767px) {
		.column.column2,
		.column.column2 li:first-child,
		.column.column2 li {
			display: block;
			width: 100%;
			padding-left: 0;
		}
		.column.column2 li:first-child {
			margin-bottom: 2em;
		}
	}
}
.s-feature-davidbowie {
	width: 100%;
	margin: auto;
	.hero img {
		width: 100%;
		margin-bottom: 2em;
	}
	.column.column2 {
		display: table;
		width: 100%;
		margin: 2em 0;
	}
	.column.column2 li {
		width: auto;
		display: table-cell;
		vertical-align: top;
		padding-left: 2em;
		img {
			display: block;
			width: 100%;
		}
	}
	.column.column2 li:first-child {
		width: 200px;
		padding-left: 0;
		text-align: center;
	}
	.buy {
		display: block;
		background-color: #6D6D6D;
		color: #fff;
		font-weight: bold;
		padding: .2em 2em;
		text-align: center;
		box-sizing: border-box;
	}
	.item-title {
		color: #A5884D;
		font-size: 1.8rem;
		margin: 0;
		padding-bottom: 0.5rem;
		&:before {
			display: none;
		}
	}
	.item-text {
		font-size: 1.1rem;
	}
	@media screen and (max-width: 767px) {
		.column.column2,
		.column.column2 li:first-child,
		.column.column2 li,
		.column.column2.reverse li:first-child,
		.column.column2.reverse li {
			display: block;
			width: 100%;
			padding-left: 0;
		}
		.buy {
			display: block;
			width: 100%;
		}
	}
}