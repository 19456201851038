@charset "UTF-8";
// ==========================================================================
// box
// エディタ内のボックスの設定
// ==========================================================================
.mceContentBody,
.post {
	.u-box {
		width: 100%;
		margin-bottom: 1rem;
		padding: 1rem 2rem;
		text-align: center;
		border: 2px solid #ddd;
		border-radius: 2px;
		box-sizing: border-box;
		&-01 {
			margin-bottom: 4rem;
			padding: 3rem;
			border: 1px solid #979797;
			@media #{$sp} {
				padding: 1rem;
			}
			&--gray {
				@extend .u-box-01;
				background-color: #f3f3f3;
			}
		}
		&-02 {
			margin-bottom: 4rem;
			padding: 3rem;
			background-color: #f3f3f3;
			border: 1px solid #ddd;
			@media #{$sp} {
				padding: 1rem;
			}
			h3 {
				margin: 0 0 2rem;
				@media #{$sp} {
					margin: 0 0 1rem;
				}
			}
			&__inner {
				padding: 3rem;
				border: 1px solid #979797;
				background-color: #fff;
			}
			&--black {
				margin-bottom: 4rem;
				padding: 3rem;
				background-color: #444;
				h3 {
					margin: 0 0 2rem;
					color: #fff;
					@media #{$sp} {
						margin: 0 0 1rem;
					}
				}
			}
		}
		&-03 {
			margin-bottom: 2rem;
			padding: 2.5rem;
			border: 3px solid #e6e6e6;
			border-radius: 3px;
			box-sizing: border-box;
			@media #{$sp} {
				padding: 1.5rem;
			}
			h3 {
				margin: 0 0 3rem;
				color: #1c4282;
				font-size: 2rem;
				span {
					padding-left: 1rem;
					font-size: 1.4rem;
				}
			}
			&__inner {
				display: table;
				width: 100%;
				@media #{$sp} {
					display: block;
				}
				&--text {
					display: table-cell;
					width: auto;
					vertical-align: middle;
					@media #{$sp} {
						display: block;
						width: 100%;
						margin-bottom: 2rem;
					}
					p {
						margin: 0;
					}
				}
				&--btn {
					display: table-cell;
					width: 50%;
					vertical-align: middle;
					@media #{$sp} {
						display: block;
						width: 100%;
					}
				}
			}
		}
		&-btn {
			margin-bottom: 2rem;
			padding: 2rem 3rem;
			border: 1px solid #ddd;
			overflow: hidden;
			a {
				float: left;
				margin-right: 1rem;
				@media #{$sp} {
					width: 100%;
				}
			}
		}
		&-event {
			display: table;
			width: 100%;
			margin: 3rem 0;
			@media #{$sp} {
				display: block;
			}
			&__image {
				display: table-cell;
				width: 20rem;
				@media #{$sp} {
					display: block;
					width: 100%;
					margin-bottom: 2rem;
				}
				img {
					box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.12);
					width: 100%;
				}
			}
			&__text {
				display: table-cell;
				width: auto;
				padding-left: 2rem;
				vertical-align: top;
				&--small {
					font-size: 1.1rem;
				}
				@media #{$sp} {
					display: block;
					width: 100%;
					padding-left: 0;
				}
				h3 {
					font-size: 1.8rem;
					margin-top: 0;
				}
			}
			&__buy {
				display: block;
				margin-top: 1.5rem;
				background-color: #6d6d6d;
				color: #fff;
				font-weight: bold;
				padding: .5em 2em;
				text-align: center;
				box-sizing: border-box;
			}
		}
	}
}