@charset "UTF-8";
// ==========================================================================
// Variable
// グローバルな変数を設定します。それ以外はそのscssファイルのみで完結してください。
// ==========================================================================

// -----------------------------------------------------------------
// Font Family
// -----------------------------------------------------------------

$fontFamily: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "sans-serif";

$fontFamilyRank: Futura, "Century Gothic", CenturyGothic, AppleGothic, "Trebuchet MS", Arial, sans-serif;


// -----------------------------------------------------------------
// Color
// -----------------------------------------------------------------

//base font color
$colorBase: #000;

//base font thin color
$colorBaseThin: #4A4A4A;

//base font thin ceolor
$colorBaseThinner: #666;

//accent color
$colorAccent: #FA5454;

//price color
$colorPrice: #D0021B;

//amazon color
$colorAmazon: #ff9500;

//link color
$colorLink: #019EF7;

//bg thin color
$colorBgThin: #f5f5f5;

//bg thin color
$colorBgThinner: #F1F1F1;

//border color
$colorBdr: #ddd;

//border thin color
$colorBdrThin: #e5e5e5;

//cat color
$colorMagazine: #FBB40F;
$colorBooks: #6AC80B;
$colorMusicScore: #E12C72;
$colorMusicManual: #A344C3;
$colorCdDvdOthers: #0C8ECA;

//social color
$colorFb: #3b5998;
$colorTw: #55acee;
$colorLn: #00d014;


// -----------------------------------------------------------------
// メインフレームの設定
// -----------------------------------------------------------------

//SPの左右スペース
$spSpace: 1.5rem;

//PCのリキッドの最大幅（MAXは1200px想定）
$pcFullWidth: 1200px;

//PCのコンテナの左右の幅（左右に20pxずつ持たせる）
$pcWrapSpace: 20px;

//PCのサイドバーのサイズ（1200pxだと20%）
$pcSidebarWidth: 24rem;


// -----------------------------------------------------------------
// Scale, Size
// -----------------------------------------------------------------

//角丸の大きさ
$radius: 3px;


// -----------------------------------------------------------------
// レスポンシブの設定
// -----------------------------------------------------------------

//スマホ用
$sp: "screen and (max-width:767px)";

//タブレット用
$tb: "screen and (min-width:768px) and (max-width:1024px)";

//PCスモール用
$pcSmall: "screen and (min-width:768px) and (max-width:1200px)";

//PC用
$pc: "screen and (min-width:1025px)";

//タブレット&PC用
$tbpc: "screen and (min-width:768px)";


// -----------------------------------------------------------------
// 配列
// -----------------------------------------------------------------

//親カテゴリのスラッグを配列化
$arrayCat: magazine, books, music_score, music_manual, cd_dvd_othens;

//親カテゴリの色を配列化
$arrayCatColor: $colorMagazine, $colorBooks, $colorMusicScore, $colorMusicManual, $colorCdDvdOthers;


// ここから下はold。使わないようにして削除する方針です。 ////////////////////////////////////////////////////////

// -----------------------------------------------------------------
// Color カラーの設定
// -----------------------------------------------------------------

//ベースフォントより薄い色
$baseFontThinColor: #666;

//ボーダーの色
$bdrColor: #ccc;
$bdrThinColor: #ddd;

//背景の色
$bgThinColor: #f5f5f5;



