@charset "UTF-8";
// ==========================================================================
// Corporate
// 会社概要周りのページの設定
// ==========================================================================
.s-corporate {
	width: 100%;
	&-box {
		display: table;
		margin-bottom: 3rem;
		&__image {
			display: table-cell;
			width: 14%;
			img {
				width: 100%;
				box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.22);
			}
		}
		&__text {
			display: table-cell;
			width: auto;
			padding: 0 2rem 1rem;
			vertical-align: top;
			h5 {
				margin-top: 0;
			}
		}
	}
	&-header {
		width: 100%;
		max-width: 1240px;
		min-width: 1024px;
		margin: 0 auto;
		padding: 0 2rem;
		box-sizing: border-box;
		@media #{$sp} {
			display: none;
		}
		&__wrap {
			width: 100%;
			background-color: #f1f1f1;
		}
		&__rights-wrap{
			width: 100%;
			margin: -6rem 0 6rem;
			border-bottom: 1px solid #1c4282;
			@media #{$sp}{
				margin-top: 0;
				border: none;
			}
		}
		&__home {
			display: table-cell;
			vertical-align: middle;
			a {
				display: inline-block;
				padding: 1rem 2rem 1rem 4rem;
				position: relative;
				color: #fff;
				background-color: #9b9b9b;
				&:before {
					content: "◀︎";
					position: absolute;
					top: 1rem;
					left: 1.5rem;
				}
			}
		}
		&__contents {
			display: table-cell;
			vertical-align: middle;
			text-align: right;
			width: 100%;
			&-btn {
				display: inline-block;
				vertical-align: text-top;
				padding: 0 1.4rem 0 2.6rem;
				@media #{$sp} {
					display: none;
				}
				a {
					-webkit-transition: background 0.1s ease-out;
					transition: background 0.1s ease-out;
					width: 14rem;
					box-sizing: border-box;
					border: #fff solid 2px;
					color: #fff;
					display: block;
					font-size: 1.1rem;
					font-weight: bold;
					text-align: center;
					height: 3.5rem;
					padding: .8rem 0 0;
				}
			}
			&-social {
				display: inline-block;
				vertical-align: top;
				ul {
					display: table;
					width: 100%;
					li {
						display: table-cell;
						vertical-align: middle;
						a {
							-webkit-transition: opacity 0.1s ease-out;
							transition: opacity 0.1s ease-out;
							display: block;
							text-align: center;
							width: 4rem;
							height: 4rem;
						}
					}
				}
				&--fb a {
					background: #3b5998 url(images/icon_header_facebook.png) no-repeat scroll 50% 50%;
					background-size: 14px 14px;
				}
				&--tw a {
					background: #55acee url(images/icon_header_twitter.png) no-repeat scroll 50% 50%;
					background-size: 14px 14px;
				}
			}
		}
		&__lower {
			width: 100%;
			max-width: 1200px;
			min-width: 1024px;
			margin: 0 auto;
			@media #{$sp} {
				display: none;
			}
		}
		&__list {
			display: table;
			width: 100%;
			vertical-align: middle;
			font-size: 1.2rem;
			li {
				display: table-cell;
				width: 16.666%;
				text-align: center;
				vertical-align: middle;
				color: #1c4282;
				font-size: 1.4rem;
				a {
					display: block;
					width: 100%;
					padding: 1.5rem;
					color: #1c4282;
					box-sizing: border-box;
				}
			}
		}
		&__rights {
			display: table;
			width: 100%;
			vertical-align: middle;
			font-size: 1.2rem;
			border-left: 1px solid #1c4282;
			@media #{$sp}{
				display: block;
				border-left: none;
			}
			li {
				display: table-cell;
				width: 16.666%;
				text-align: center;
				vertical-align: middle;
				color: #1c4282;
				font-size: 1.4rem;
				border-right: 1px solid #1c4282;
				box-sizing: border-box;
				@media #{$sp}{
					display: block;
					width: 100%;
					border-right: none;
					border-bottom: 1px solid #1c4282;
				}
				a {
					display: block;
					width: 100%;
					padding: 1.5rem;
					color: #1c4282;
					box-sizing: border-box;
				}
			}
		}
		&-biz {
			position: relative;
			z-index: 999;
			cursor: pointer;
			&__list {
				position: relative;
				text-align: center;
				background-color: #fff;
				border: 3px solid #1c4282;
				border-radius: 3px;
				&:before {
					content: "";
					position: absolute;
					top: -8px;
					left: 50%;
					margin-left: -9px;
					display: block;
					border-style: solid;
					border-width: 0 9px 9px 9px;
					border-color: transparent transparent #F6F6F6 transparent;
				}
				&:after {
					content: "";
					position: absolute;
					top: -12px;
					left: 50%;
					margin-left: -10px;
					display: block;
					border-style: solid;
					border-width: 0 10px 10px 10px;
					border-color: transparent transparent #19283C transparent;
					z-index: -1;
				}
				&-wrap {
					width: 100%;
					position: absolute;
					top: 5.1rem;
					left: 0;
					&--en {
						width: 100%;
						position: absolute;
						top: 7.2rem;
						left: 0;
					}
				}
				&--hidden {
					display: none;
				}
				&--visible {
					display: block;
				}
				li {
					display: block;
					width: 100%;
					a {
						display: block;
						padding: 1rem;
						&:hover {
							font-weight: bold;
							background-color: #f1f1f1;
						}
					}
				}
			}
		}
	}
}

.message .current-message,
.corporate .current-corporate,
.english .current-english,
.history .current-history,
.map .current-map,
.overseas .current-overseas,
.jobs .current-jobs {
	background-color: #9b9b9b;
	a {
		color: #fff;
	}
}

.rights,
.music,
.music-domestic,
.rightsuse,
.merchandising,
.book,
.copyright,
.master,
.artist,
.other {
	.s-corporate-header-biz {
		color: #fff;
		background-color: #9b9b9b;
	}
}

.rights .s-corporate-header-biz__rights,
.music .s-corporate-header-biz__music,
.music-domestic .s-corporate-header-biz__music-domestic,
.rightsuse .s-corporate-header-biz__rightsuse,
.merchandising .s-corporate-header-biz__merchandising,
.copyright .s-corporate-header-biz__rights {
	color: #fff;
	background-color: #9b9b9b;
	a{
		color: #fff;
	}
}


.history {
	dl {
		dt {
			font-size: 1.5rem;
		}
		dd {
			padding-bottom: 1.5rem;
		}
	}
}

@media #{$sp} {
	.s-corporate {
		width: 100%;
		&-box {
			display: block;
			margin-bottom: 3rem;
			&__image {
				display: block;
				width: 100%;
				img {
					width: 100%;
				}
			}
			&__text {
				display: block;
				width: 100%;
				padding: 0;
			}
		}
	}
	.breadcrumb {
		margin: 1rem 1.5rem;
		li {
			display: inline;
			color: #666;
			font-size: 1.2rem;
			a {
				color: #666;
			}
		}
	}
}

@media #{$tbpc} {
	.merchandising .corporate-page,
	.rightsuse .corporate-page,
	.music-domestic .corporate-page,
	.music .corporate-page,
	.english .corporate-page,
	.overseas .corporate-page,
	.corporate .corporate-page,
	.map .corporate-page,
	.message .corporate-page,
	.artist .corporate-page,
	.book .corporate-page,
	.copyright .corporate-page,
	.master .corporate-page,
	.other .corporate-page,
	.history .corporate-page,
	.detail .corporate-page,
	.jobs .corporate-page {
		max-width: 100%;
		padding: 0;
		.main__headline {
			margin: 0 0 6rem;
			height: 12.5rem;
			background-image: url(images/headerBg.png);
			background-repeat: repeat;
			background-position: 0 0;
			background-size: 12.5rem 12.5rem;
			span {
				display: block;
				width: 100%;
				height: 12.5rem;
				max-width: 1240px;
				min-width: 1024px;
				margin: 0 auto;
				padding: 3.5rem 2rem;
				position: relative;
				font-size: 3.6rem;
				font-weight: normal;
				color: #fff;
				background-color: transparent;
				background-repeat: no-repeat;
				background-position: 100% 50%;
				background-size: 77.1rem 12.5rem;
				box-sizing: border-box;
			}
		}
		.post {
			width: 100%;
			max-width: 1240px;
			min-width: 1024px;
			margin: 0 auto 15rem;
			padding: 0 2rem;
			box-sizing: border-box;
		}
	}
	.corporate .corporate-page,
	.english .corporate-page {
		.main__headline span {
			background-image: url(images/headerBg_outline.png);
		}
	}
	.overseas .corporate-page {
		.main__headline span {
			background-image: url(images/headerBg_english_overseas.png);
		}
	}
	.artist .corporate-page {
		.main__headline span {
			background-image: url(images/headerBg_business_artists.png);
		}
	}
	.book .corporate-page {
		.main__headline span {
			background-image: url(images/headerBg_business_publishing.png);
		}
	}
	.merchandising .corporate-page,
	.rightsuse .corporate-page,
	.music-domestic .corporate-page,
	.music .corporate-page,
	.copyright .corporate-page {
		.main__headline span {
			background-image: url(images/headerBg_business_copyright.png);
		}
	}
	.master .corporate-page {
		.main__headline span {
			background-image: url(images/headerBg_business_master.png);
		}
	}
	.other .corporate-page {
		.main__headline span {
			background-image: url(images/headerBg_business_overseas.png);
		}
	}
	.map .corporate-page {
		.main__headline span {
			background-image: url(images/headerBg_map.png);
		}
		.post {
			iframe {
				margin-top: 2rem;
			}
			.corporate-page__wrap {
				margin: 0 auto;
			}
		}
	}
	.message .corporate-page {
		.main__headline span {
			background-image: url(images/headerBg_message.png);
		}
	}
	.history .corporate-page,.detail .corporate-page {
		.main__headline span {
			background-image: url(images/headerBg_history.png);
		}
	}
	.jobs .corporate-page {
		.main__headline span {
			background-image: url(images/headerBg_business_jobs.png);
		}
	}
	.s-corporate {
		&-2column {
			width: 100%;
			margin-bottom: 7rem;
			overflow: hidden;
			&__left {
				width: 48%;
				float: left;
			}
			&__right {
				width: 48%;
				float: right;
			}
		}
		&-info {
			padding: 0 11.5rem 2rem;
			border: 1px solid #1387c9;
			border-radius: 3px;
		}
		&-history {
			display: table;
			margin-bottom: 6rem;
			&__era {
				display: table-cell;
				width: 26rem;
				padding: 2rem 0;
				position: relative;
				text-align: center;
				vertical-align: middle;
				color: #fff;
				font-size: 1.6rem;
				border-radius: 3px;
				&--01 {
					@extend .s-corporate-history__era;
					background-color: #63bee4;
					&:after {
						content: "●";
						position: absolute;
						bottom: -2.5rem;
						left: 50%;
						color: #63BEE5;
						font-size: 0.7rem;
					}
				}
				&--02 {
					@extend .s-corporate-history__era;
					background-color: #1387c9;
					&:before {
						content: "●";
						position: absolute;
						top: -2.5rem;
						left: 50%;
						color: #1387c9;
						font-size: 0.7rem;
					}
					&:after {
						content: "●";
						position: absolute;
						bottom: -2.5rem;
						left: 50%;
						color: #1387c9;
						font-size: 0.7rem;
					}
				}
				&--03 {
					@extend .s-corporate-history__era;
					background-color: #1357c9;
					&:before {
						content: "●";
						position: absolute;
						top: -2.5rem;
						left: 50%;
						color: #1357c9;
						font-size: 0.7rem;
					}
				}
				&--04 {
					@extend .s-corporate-history__era;
					background-color: #1C4282;
					&:before {
						content: "●";
						position: absolute;
						top: -2.5rem;
						left: 50%;
						color: #1C4282;
						font-size: 0.7rem;
					}
					&:after {
						content: "●";
						position: absolute;
						top: -5rem;
						left: 50%;
						color: #1357c9;
						font-size: 0.7rem;
					}
				}
			}
			&__text {
				display: table-cell;
				padding-left: 4rem;
				vertical-align: middle;
			}
		}
		&-detail {
			position: relative;
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 1rem;
				left: 0.6rem;
				width: 1px;
				height: 99.7%;
				background-color: #979797;
			}
			th {
				width: 12.8rem;
				padding-left: 2rem;
				position: relative;
				text-align: left;
				font-size: 2.2rem;
				font-weight: normal;
				&:before {
					content: "●";
					position: absolute;
					top: 0.6rem;
					left: 0;
					color: #1c4282;
					font-size: 1.4rem;
				}
			}
			td h3:first-child {
				margin: 0 0 1rem;
			}
		}
		&-nav {
			display: table;
			width: 100%;
			margin-top: 6rem;
			border: 1px solid #979797;
			border-radius: 3px;
			box-sizing: border-box;
			li {
				display: table-cell;
				width: 20%;
				vertical-align: middle;
				font-size: 1.6rem;
				box-sizing: border-box;
				&+li {
					border-left: 1px solid #979797;
				}
				a {
					display: block;
					width: 100%;
					padding: .5rem;
					text-align: center;
					color: #9b9b9b;
					box-sizing: border-box;
				}
			}
			&__current {
				padding: .5rem;
				text-align: center;
				color: #1c4282;
				background-color: #f1f1f1;
			}
		}
	}
}

@media #{$tbpc} {
	body.page-template-page-corporate-en {
		min-width: 1185px;
	}
	.corporate-page__wrap {
		width: 1024px;
		margin: 0 auto;
	}
}
@media #{$sp}{
	.s-corporate-header__rights-wrap{
		.s-corporate-header{
			display: block;
			min-width: 100%;
			max-width: 100%;
			padding: 0;
		}
	}
	.s-corporate-detail h3{
		padding-left: 1rem;
	}
}