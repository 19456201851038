@charset "UTF-8";
// ==========================================================================
// Lists
// エディタ内のリストの設定
// ==========================================================================
.mceContentBody,
.post {
	.list-01 {
		margin-bottom: 2rem;
		li {
			padding: 0.3rem 0.3rem 0.3rem 1.7rem;
			text-indent: -1.7rem;
			&:before {
				content: '・ ';
				color: #51728c;
			}
		}
	}
	.list-02 {
		margin-bottom: 2rem;
		li {
			padding: 0 1rem 1rem 2rem;
			position: relative;
			&:before {
				content: '';
				display: block;
				width: 10px;
				height: 5px;
				position: absolute;
				top: 0.7rem;
				left: 0;
				background-color: #000;
			}
		}
	}
	.list-03 {
		margin-bottom: 2rem;
		li {
			padding: 1rem;
			&:nth-child(odd) {
				background-color: #f1f1f1;
			}
		}
	}
	.list-04 {
		margin-bottom: 2rem;
		padding-left: 1.6rem;
		li {
			padding: 0.3rem 0.3rem 1rem 0.3rem;
			list-style: decimal;
		}
	}
	.list-05 {
		margin-bottom: 1rem;
		background-color: #fff;
		border: 1px solid #ddd;
		li {
			padding: 1rem;
			border-bottom: 1px solid #ddd;
		}
		li:last-child {
			border-bottom: none;
		}
	}
	.list-06 {
		margin-bottom: 2rem;
		background-color: #fff;
		border: 1px solid #ddd;
		li {
			padding: 1.5rem;
			position: relative;
			border-bottom: 1px solid #ddd;
			a {
				position: absolute;
				top: 0;
				right: 1.2rem;
				@media #{$sp} {
					width: 100%;
					margin: 1rem 0 0;
					position: static;
				}
			}
		}
		li:last-child {
			border-bottom: none;
		}
	}
	.list-07 {
		margin-bottom: 1rem;
		li {
			padding: 1rem 0;
			position: relative;
			border-bottom: 1px solid #979797;
		}
	}
	.list-08 {
		width: 100%;
		margin-bottom: 2rem;
		border-top: 1px solid #ddd;
		border-left: 1px solid #ddd;
		overflow: hidden;
		box-sizing: border-box;
		li {
			width: 50%;
			float: left;
			border-right: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
			box-sizing: border-box;
			@media #{$sp} {
				width: 100%;
			}
			a {
				display: block;
				padding: 1rem 2rem 1rem 1rem;
				text-decoration: underline;
				color: #000;
				background-image: url(images/icon_links.png);
				background-repeat: no-repeat;
				background-position: 97% 50%;
				background-size: 0.7rem 1rem;
			}
		}
	}
	.list-09 {
		width: 100%;
		margin-top: 6rem;
		margin-bottom: 2rem;
		li {
			width: 80%;
			margin: 0 auto 2rem;
			border: 3px solid #ddd;
			border-radius: 3px;
			box-sizing: border-box;
			&:hover {
				border: 3px solid #fa5454;
			}
			@media #{$sp} {
				width: 100%;
			}
			a {
				display: block;
				padding: 1rem 2rem 1rem 6rem;
				color: #000;
				background-image: url(images/icon_links.png);
				background-repeat: no-repeat;
				background-position: 97% 50%;
				background-size: 0.7rem 1rem;
			}
		}
		&--faq {
			position: relative;
			&:after {
				content: "";
				width: 2.9rem;
				height: 2.5rem;
				position: absolute;
				top: 0.7rem;
				left: 2rem;
				background-image: url(images/icon_faq.png);
				background-repeat: no-repeat;
				background-size: 2.9rem 2.5rem;
			}
		}
		&--contact {
			position: relative;
			&:after {
				content: "";
				width: 2.7rem;
				height: 2rem;
				position: absolute;
				top: 1rem;
				left: 2rem;
				background-image: url(images/icon_contact.png);
				background-repeat: no-repeat;
				background-size: 2.7rem 2rem;
			}
		}
		&--request {
			position: relative;
			&:after {
				content: "";
				width: 2.1rem;
				height: 3rem;
				position: absolute;
				top: 0.5rem;
				left: 2.3rem;
				background-image: url(images/icon_request.png);
				background-repeat: no-repeat;
				background-size: 2.1rem 3rem;
			}
		}
	}
	.list-10 {
		width: 100%;
		margin-bottom: 2rem;
		overflow: hidden;
		box-sizing: border-box;
		li {
			width: 30%;
			min-height: 13.5rem;
			margin-right: 5%;
			margin-bottom: 2rem;
			float: left;
			text-align: center;
			border: 3px solid #ddd;
			box-sizing: border-box;
			&:hover {
				border: 3px solid #fa5454;
			}
			@media #{$sp} {
				width: 100%;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
			a {
				display: block;
				padding: 3rem 0 2rem;
				color: #333;
				font-weight: bold;
				img {
					display: block;
					margin: 0 auto 1.5rem;
				}
			}
		}
	}
	.list-11 {
		margin-top: 2rem;
		padding-top: 2rem;
		border-top: 3px solid #e6e6e6;
		li {
			padding: 0.3rem 0.3rem 0.3rem 1.7rem;
			text-indent: -1.7rem;
			&:before {
				content: '・ ';
				color: #51728c;
			}
		}
	}
	.list-corporate {
		display: table;
		margin-bottom: 3rem;
		border: 1px solid #ddd;
		@media #{$sp} {
			display: block;
		}
		li {
			display: table-cell;
			width: 20%;
			border-right: 1px solid #ddd;
			box-sizing: border-box;
			@media #{$sp} {
				display: block;
				width: 100%;
				border-right: none;
				border-bottom: 1px solid #ddd;
			}
			&:last-child {
				border-right: none;
				@media #{$sp} {
					border-bottom: none;
				}
			}
			a {
				box-sizing: border-box;
				width: 100%;
				padding: 2rem 2rem 0.3rem;
				color: #000;
				display: block;
				@media #{$sp} {
					padding: 0;
				}
				&:hover {
					background-color: #f3f3f3;
				}
				span {
					background: url(images/icon_links.png) no-repeat scroll right 50%;
					background-size: 7px 10px;
					display: block;
					padding: 1.2rem 0;
					text-align: center;
					font-size: 1.6rem;
					@media #{$sp} {
						background: url(images/icon_links.png) no-repeat scroll 97% 50%;
						background-size: 7px 10px;
					}
				}
			}
			img {
				width: 100%;
				@media #{$sp} {
					display: none;
				}
			}
		}
	}
	.list-artist {
		width: 100%;
		-js-display: flex;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content: space-around;
		border-top: $bdrThinColor solid 1px;
		border-left: $bdrThinColor solid 1px;
		@media #{$sp} {
			display: block;
			margin-bottom: 1rem;
		}
		li {
			border-right: $bdrThinColor solid 1px;
			border-bottom: $bdrThinColor solid 1px;
			width: 50%;
			box-sizing: border-box;
			padding: 3% 3% 9rem;
			position: relative;
			@media #{$sp} {
				width: 100%;
				padding: 1rem;
			}
		}
		&__wrap {
			display: table;
			width: 100%;
			overflow: hidden;
			@media #{$sp} {
				display: block;
			}
			& + .list-artist__wrap {
				position: absolute;
				bottom: 2.5rem;
				left: 6%;
				width: 88%;
				@media #{$sp} {
					position: static;
					width: 100%;
				}
				.list-artist__img {
					width: 10rem;
				}
			}
		}
		&__img {
			display: table-cell;
			width: 15rem;
			padding-right: 2rem;
			img {
				width: 100%;
			}
			@media #{$sp} {
				display: block;
				margin: 0 auto 1rem;
			}
		}
		&__article {
			display: table-cell;
			width: auto;
			vertical-align: top;
			@media #{$sp} {
				display: block;
				padding-left: 0;
			}
			h3 {
				margin: 0 0 1.2rem;
				span {
					display: block;
					width: 7rem;
					background: url(images/icon_links_blue.png) no-repeat scroll right .5rem;
					background-size: 7px 10px;
					padding-right: 1.6rem;
					font-size: 1.4rem;
				}
			}
		}
		&-info {
			overflow: hidden;
			@media #{$sp} {
				display: block;
				text-align: center;
			}
			li {
				width: 33.333%;
				padding: 5%;
				float: left;
				box-sizing: border-box;
				@media #{$sp}{
					width: 100%;
				}
				img {
					width: 100%;
					border: 2px solid #ddd;
					border-radius: 5px;
					box-sizing: border-box;
				}
				h3 {
					margin: 1rem 0;
					font-weight: normal;
				}
			}
		}
	}
	.score-tab {
		&--3 {
			margin-top: -1em;
			width: 100%;
			overflow: hidden;
			li {
				width: 33.3333%;
				float: left;
				img{
					width: 100%;
				}
			}
		}
		&--4 {
			margin-top: -1em;
			max-width: 100%;
			overflow: hidden;
			li {
				width: 25%;
				float: left;
				img{
					width: 100%;
				}
			}
		}
	}
	.download {
		padding-bottom: 2rem;
		li {
			padding: 0.4rem 0 0.4rem 2rem;
			background-image: url(images/pdf_small.gif);
			background-repeat: no-repeat;
			background-position: 0 0.5rem;
			background-size: 15px 16px;
		}
	}
}