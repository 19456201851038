@charset "UTF-8";
// ==========================================================================
// Button
// ボタン用のパーツ
// ==========================================================================
//リンク遷移のボタン
.c-btn {
	background: #fff;
	box-sizing: border-box;
	border-radius: 2px;
	display: block;
	font-weight: bold;
	border: $bdrColor solid 1px;
	color: $colorBase;
	text-align: center;
	padding: 1rem 0.4rem;
	&:hover {
		opacity: 0.7;
		text-decoration: none;
	}
}

.c-btn--inline {
	box-sizing: border-box;
	background: #fff;
	border: $bdrColor solid 1px;
	border-radius: 3px;
	display: inline-block;
	font-weight: bold;
	color: $colorBase;
	text-align: center;
	font-size: 1.2rem;
	text-align: center;
	min-width: 7.5rem;
	padding: 1.1rem 1rem;
}

.c-btn__submit {
	display: block;
	width: 20rem;
	margin: 0 auto;
	padding: 1rem;
	text-align: center;
	font-weight: bold;
	background-color: #fa5454;
	border-radius: 3px;
	cursor: pointer;
	input {
		margin: 0;
		padding: 0;
		background: none;
		border: none;
		border-radius: 0;
		outline: none;
		font-size: 1.4rem;
		color: #fff;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
	img{
		display: none;
	}
}