@charset "UTF-8";
// ==========================================================================
// button
// エディタ内のボタンの設定
// ==========================================================================
.mceContentBody,
.post {
	.u-btn {
		display: inline-block;
		width: 20rem;
		margin: 1rem 0;
		padding: 1rem;
		text-align: center;
		font-weight: bold;
		color: #fff;
		background-color: #47ACD5;
		border-radius: 3px;
		box-sizing: border-box;
		&:hover{
			opacity: 0.8;
			text-decoration: none;
		}
		&__dl {
			display: inline-block;
			width: 17rem;
			margin: 1rem 0;
			padding: 0.6rem 0 0.6rem 2rem;
			text-align: center;
			font-size: 1.2rem;
			color: #fff;
			background-color: #47ACD5;
			background-repeat: no-repeat;
			background-position: 9% 50%;
			border-radius: 3px;
			box-sizing: border-box;
			&--pdf {
				@extend .u-btn__dl;
				background-image: url(images/icon_pdf.png);
				background-size: 1.4rem 1.8rem;
				&:hover{
					opacity: 0.8;
					text-decoration: none;
				}
			}
			&--xls {
				@extend .u-btn__dl;
				background-image: url(images/icon_xls.png);
				background-size: 1.4rem 1.8rem;
				&:hover{
					opacity: 0.8;
					text-decoration: none;
				}
			}
			&--generic {
				@extend .u-btn__dl;
				background-image: url(images/icon_dl.png);
				background-size: 1.4rem 1.6rem;
				&:hover{
					opacity: 0.8;
					text-decoration: none;
				}
			}
			&--goods {
				@extend .u-btn__dl;
				background-image: url(images/icon_dl_02.png);
				background-size: 1.5rem 1.5rem;
				&:hover{
					opacity: 0.8;
					text-decoration: none;
				}
			}
		}
		&--black {
			display: inline-block;
			width: 20rem;
			margin: 1rem 0;
			padding: 1rem;
			text-align: center;
			font-weight: bold;
			color: #fff;
			background-color: #000;
			border-radius: 3px;
			box-sizing: border-box;
		}
		&--white {
			display: inline-block;
			width: 20rem;
			margin: 1rem 0;
			padding: 1rem;
			text-align: center;
			font-weight: bold;
			color: #000;
			border: 1px solid #f6e6d0;
			border-radius: 3px;
			box-sizing: border-box;
		}
		&--submit {
			display: block;
			width: 100%;
			margin: 1rem auto;
			text-align: center;
			font-weight: bold;
			input {
				display: inline-block;
				width: 20rem;
				margin: 0 auto;
				padding: 1rem;
				background: none;
				border: none;
				border-radius: 3px;
				outline: none;
				font-size: 1.4rem;
				color: #fff;
				background-color: #47ACD5;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				cursor: pointer;
				&:hover{
					opacity: 0.8;
					text-decoration: none;
				}
				&+input{
					margin-left: 3rem;
					@media #{$sp} {
						margin-top: 1rem;
						margin-left: 0;
					}
				}
			}
			img {
				display: none;
			}
		}
		&--amazon {
			display: inline-block;
			width: 100%;
			margin: 0;
			padding: 0.7rem;
			text-align: center;
			font-weight: bold;
			color: #000;
			background-color: #f9f6e4;
			border: 1px solid #f49b08;
			border-radius: 3px;
			box-sizing: border-box;
		}
		&--window {
			display: inline-block;
			padding-right: 2rem;
			color: #019EF7;
			background-image: url(images/icon_window.png);
			background-repeat: no-repeat;
			background-position: 100% 2px;
			background-size: 1.5rem 1.3rem;
		}
		&--back {
			display: block;
			width: 20rem;
			margin: 1rem auto;
			padding: 1rem;
			text-align: center;
			font-weight: bold;
			color: #1c4282;
			border: 2px solid #1c4282;
			border-radius: 3px;
			box-sizing: border-box;
		}
		&--contact {
			display: inline-block;
			width: 100%;
			padding: 1.5rem 0 1.5rem 3rem;
			text-align: center;
			color: #fff;
			font-size: 1.6rem;
			background-color: #1c4282;
			border-radius: 3px;
			box-sizing: border-box;
			span {
				position:relative;
				&:before {
					content: "";
					display: inline-block;
					width: 2.6rem;
					height: 2rem;
					position: absolute;
					top: -0.2rem;
					left: -4rem;
					background-image: url(images/icon_mail.png);
					background-repeat: no-repeat;
					background-size: 2.6rem 2rem;
				}
			}
		}
	}
}