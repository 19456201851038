@charset "UTF-8";
// ==========================================================================
// social
// ソーシャルボタンのパーツ
// ==========================================================================

.c-social {
	box-sizing: border-box;
	margin: 0 0 1.6rem;
	
	@media #{$sp} {
		margin: 0 0 1rem;
		text-align: left;
	}
	
	@media #{$tbpc} {
		//@include table(100%);
	}
	
	.c-social__body {
		text-align: left;
		display: inline-block;
	}
	
	ul {
		@include table(100%);
		table-layout: fixed;
		
		@media #{$sp} {
			width: 4.5rem * 3;
			
			li {
				@include tableCell(middle);
			}
		}
		
		a {
			display: block;
		}
	}
	
	@media #{$sp} {
		li {
			a {
				width: 4.5rem;
				height: 4.5rem;
				
				span {
					display: none;
				}
			}
		}
		
		.c-social__button--tw a {
			background: #55acee url(images/icon_social_twitter.png) no-repeat scroll 50% 50%;
			background-size: 19px 16px;
		}
		.c-social__button--fb a {
			background: #3b5998 url(images/icon_social_facebook.png) no-repeat scroll 50% 50%;
			background-size: 19px 20px;
		}
		.c-social__button--ln a {
			background: #00d014 url(images/icon_social_line.png) no-repeat scroll 50% 50%;
			background-size: 27px 26px;
		}
	}
	@media #{$tbpc} {
		li {
			box-sizing: border-box;
			display: inline-block;
			padding: 0 .5rem 0 0;
			
			&:nth-child(even) {
				padding: 0 0 0 .5rem;
			}
			
			a {
				@include animeAlpha;
				border-radius: $radius;
				width: 12rem;
				
				&:hover {
					@include animeAlphaHover(80);
					text-decoration: none;
				}
				
				span {
					display: block;
					color: #fff;
					padding: 0.2rem 0 0.2rem 1rem;
					text-align: center;
					font-size: 13px;
				}
			}
		}
		
		.c-social__button--ln {
			@media #{$tbpc} {
				display: none;
			}
		}
		
		.c-social__button--tw a {
			background: #55acee url(images/icon_social_twitter.png) no-repeat scroll 50% 50%;
			background-size: 19px 16px;
			
			@media #{$tbpc} {
				background-position: .8rem 50%;
			}
		}
		.c-social__button--fb a {
			background: #3b5998 url(images/icon_social_facebook.png) no-repeat scroll 50% 50%;
			background-size: 19px 20px;
			
			@media #{$tbpc} {
				background-position: .8rem 50%;
			}
		}
		.c-social__button--ln a {
			background: #00d014 url(images/icon_social_line.png) no-repeat scroll 50% 50%;
			background-size: 27px 26px;
		}
	}
	
}
