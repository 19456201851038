@charset "UTF-8";
// ==========================================================================
// Apology
// お詫びと一覧
// ==========================================================================

@media #{$sp} {
	.p-apology {
		padding: 0 $spSpace;
	}
}
@media #{$tbpc} {
	.p-apology {
		max-width: 1080px;
		margin: 0 auto;
	}
}


//一覧
@media #{$sp} {
	.p-apology__box {
		margin: 0 0 3rem;
	}
	
	.p-apology__title {
		text-align: center;
		font-size: 1.4rem;
		margin: 0 0 1.2rem;
	}
	
	.p-apology__list {
		border-top: $colorBdrThin solid 1px;
		
		.p-apology__item {
			border-bottom: $colorBdrThin solid 1px;
			border-left: $colorBdrThin solid 1px;
			border-right: $colorBdrThin solid 1px;
			
			a {
				box-sizing: border-box;
				width: 100%;
				color: $colorBase;
				display: block;
				text-decoration: underline;
				padding: 0 $spSpace;
				
				&:hover {
					text-decoration: none;
				}
				
				.p-apology-item__body {
					@include table(100%);
					padding: 1rem 0;
				}
				
				.p-apology-item__thumb {
					@include tableCell(middle);
					width: 6.4rem;
					
					img {
						max-width: 5rem;
						width: 100%;
						height: auto;
					}
				}
				
				.p-apology-item__tilte {
					@include spLinksIcon;
					@include tableCell(middle);
					padding: 0 1.5rem 0 0;
				}
			}
		}
	}
}
@media #{$tbpc} {
	.p-apology__box {
		margin: 0 0 6rem;
	}
	
	.p-apology__title {
		//text-align: center;
		font-size: 2rem;
		margin: 0 0 2.4rem;
	}
	
	.p-apology__list {
		border-top: $colorBdrThin solid 1px;
		
		.p-apology__item {
			border-bottom: $colorBdrThin solid 1px;
			border-left: $colorBdrThin solid 1px;
			border-right: $colorBdrThin solid 1px;
			
			a {
				box-sizing: border-box;
				width: 100%;
				color: $colorBase;
				display: block;
				text-decoration: underline;
				padding: 0 3rem;
				
				&:hover {
					text-decoration: none;
				}
				
				.p-apology-item__body {
					@include table(100%);
					padding: 1rem 0;
				}
				
				.p-apology-item__thumb {
					@include tableCell(middle);
					width: 9rem;
					
					img {
						max-width: 6.5rem;
						width: 100%;
						height: auto;
					}
				}
				
				.p-apology-item__tilte {
					@include pcLinksIcon;
					@include tableCell(middle);
					//padding: 1.2rem 0;
				}
			}
		}
	}
}


//詳細
.p-apology__box--single {
	border: #979797 solid 1px;
}

@media #{$sp} {
	.p-apology__box--single {
		margin: 0 0 3rem;
		padding: $spSpace 0 0;
	}
}
@media #{$tbpc} {
	.p-apology__title--single {
		margin: 0 0 5.6rem;
		padding: 2.6rem 0 0;
	}
	
	.p-apology__box--single {
		padding: 6rem;
	}
}
