@charset "UTF-8";
// ==========================================================================
// Text
// エディタ内のテキスト周りの設定
// ==========================================================================
.mceContentBody,
.post {
	font-size: 1.3rem;
	p {
		margin: 0 0 1em;
	}
	a {
		//color: #DC7603;
	}
	strong {
		font-weight: bold;
	}
	dl {
		margin-bottom: 3rem;
		dt,
		dd {
			padding-bottom: 0.6rem;
		}
		dt {
			font-size: 1.4rem;
		}
	}
	.signature {
		margin-top: 2rem;
		text-align: right;
		font-size: 1.4rem;
		font-weight: bold;
		span {
			display: inline-block;
			float: left;
			font-weight: normal;
		}
	}
	.text-line {
		position: relative;
		z-index: 1;
		text-align: center;
		font-size: 1.4rem;
		&:after {
			content: '';
			width: 100%;
			position: absolute;
			top: 50%;
			left: 0;
			z-index: -1;
			border-top: 1px solid #ddd;
		}
		span {
			padding: 0 1em;
			display: inline-block;
			background-color: #fff;
		}
	}
	.page-top {
		margin-top: 20px;
		padding-top: 20px;
		text-align: right;
		border-top: 1px solid #ddd;
	}
	.annotation {
		color: #B7002E;
	}
	.faq {
		dt {
			padding: 0.3rem 0 1.5rem 4rem;
			color: #1282BF;
			font-size: 2rem;
			background-image: url(images/icon_q_blue.png);
			background-repeat: no-repeat;
			background-position: 0% .4rem;
			background-size: 28px 28px;
		}
		dd {
			padding: 0 0 5rem 4rem;
			background-image: url(images/icon_a_red.png);
			background-repeat: no-repeat;
			background-position: 0% 0%;
			background-size: 28px 28px;
			font-size: 1.4rem;
			h5 {
				margin: 1rem 0 0.5rem;
				font-size: 1.5rem;
			}
		}
	}
	.text-tel {
		padding-left: 2.5rem;
		color: #1c4282;
		font-size: 2rem;
		background-image: url(images/icon_tel.png);
		background-repeat: no-repeat;
		background-position: 0% 50%;
		background-size: 1.7rem 2.4rem;
	}
	.report_body {
		font-size: 120%;
		line-height: 180%;
	}
	h2.ttl-red {
		font-size: 110%;
		background: #E10000;
		color: #fff;
		padding: 0.5em;
		&:before {
			display: none;
		}
	}
	h2.ttl-orange {
		font-size: 110%;
		background: #cd4d0f;
		color: #fff;
		padding: 0.5em;
		&:before {
			display: none;
		}
	}
	h2.ttl-coral {
		font-size: 110%;
		background: #e93b13;
		color: #fff;
		padding: 0.5em;
		&:before {
			display: none;
		}
	}
	h2.ttl-pink {
		font-size: 110%;
		background: #e42587;
		color: #fff;
		padding: 0.5em;
		&:before {
			display: none;
		}
	}
	h2.ttl-green {
		font-size: 110%;
		background: #7bac05;
		color: #fff;
		padding: 0.5em;
		&:before {
			display: none;
		}
	}
	h2.ttl-lime {
		font-size: 110%;
		background: #4cb886;
		color: #fff;
		padding: 0.5em;
		&:before {
			display: none;
		}
	}
	h2.ttl-purple {
		font-size: 110%;
		background: #ad8ebe;
		color: #fff;
		padding: 0.5em;
		&:before {
			display: none;
		}
	}
	h2.ttl-d_purple {
		font-size: 110%;
		background: #67318d;
		color: #fff;
		padding: 0.5em;
		&:before {
			display: none;
		}
	}
	h2.ttl-d_red {
		font-size: 110%;
		background: #da0025;
		color: #fff;
		padding: 0.5em;
		&:before {
			display: none;
		}
	}
	h2.ttl-blue {
		font-size: 110%;
		background: #2d63a7;
		color: #fff;
		padding: 0.5em;
		&:before {
			display: none;
		}
	}
	h2.ttl-org {
		font-size: 110%;
		background: #ffcc00;
		color: #fff;
		padding: 0.5em;
		&:before {
			display: none;
		}
	}
	h2.ttl-pur {
		font-size: 110%;
		background: #9900FF;
		color: #fff;
		padding: 0.5em;
		&:before {
			display: none;
		}
	}
	h2.ttl-gry {
		font-size: 110%;
		background: #595757;
		color: #fff;
		padding: 0.5em;
		&:before {
			display: none;
		}
	}
	h2.ttl-dd_red {
		font-size: 110%;
		background: #AA0000;
		color: #EFE69F;
		padding: 0.5em;
		&:before {
			display: none;
		}
	}
	.text-pur {
		font-weight: bold;
		color: #9900FF;
	}
	.text-red {
		font-weight: bold;
		color: #dd2257;
	}
	.text-org {
		font-weight: bold;
		color: #ffcc00;
	}
	.text-pnk {
		font-weight: bold;
		color: #FF83E8;
	}
	.text-grn {
		font-weight: bold;
		color: #7bac05;
	}
	.text-blu {
		font-weight: bold;
		color: #029ce4;
	}
	.text-d_blu {
		font-weight: bold;
		color: #2d63a7;
	}
	.text-ylw {
		font-weight: bold;
		color: #ffcc00;
	}
	.text-small {
		font-size: 80%;
	}
	h3.caption {
		margin: 4rem 0 2rem;
		font-size: 120%;
	}
	.photo {
		float: right;
		margin: 0 0 1rem 1rem;
		@media #{$sp} {
			width: 100%;
		}
	}
	.photo_l {
		float: left;
		margin: 1rem 1rem 0 0;
		@media #{$sp} {
			width: 100%;
		}
	}
}