@charset "UTF-8";
// ==========================================================================
// Headline
// 見出しのパーツ
// ==========================================================================

//見出しのグループ（右にリンクがつくやつ）
@media #{$sp} {
	.c-headline-nav {
		@include table(100%);
		box-sizing: border-box;
		padding: $spSpace;
	}
	
	.c-headline-nav__title {
		@include tableCell(middle);
		font-size: 1.5rem;
		font-weight: bold;
	}
	
	.c-headline-nav__links {
		@include tableCell(middle);
		text-align: right;
		a {
			//color: $colorBase;
			display: inline-block;
			text-decoration: none;
		}
		
		span {
			@include spLinksIcon;
		}
	}
}
@media #{$tbpc} {
	.c-headline-nav {
		margin: 0 0 4rem;
		position: relative;
	}
	
	.c-headline-nav__title {
		span {
			display: inline-block;
			background: #fff;
			position: relative;
			font-size: 1.8rem;
			font-weight: bold;
			padding: 0 2rem 0 0;
		}
		
		&:before {
			content: "";
			display: block;
			background: $colorBase;
			width: 95%;
			height: .1rem;
			z-index: -1;
			margin: auto 0;
			top: 0;
			bottom: 0;
			position: absolute;
		}
	}
	
	.c-headline-nav__links {
		background: #fff;
		position: absolute;
		right: 0;
		top: .4rem;
		text-align: right;
		padding: 0 0 0 3.5rem;
		
		a {
			//color: $colorBase;
			display: inline-block;
			text-decoration: none;
			
			&:hover {
				text-decoration: underline;
			}
		}
		
		span {
			@include spLinksIcon;
		}
	}
}


//大見出し
//.c-headline {
//	font-size: 1.6rem;
//	font-weight: bold;
//	display: inline-block;
//	padding: 0 0 1rem;
//	
//	@media #{$tbpc} {
//		background: #fff;
//		font-size: 3rem;
//		font-weight: normal;
//		padding: 0 2rem 0 0;
//		line-height: 1;
//	}
//	
//	&__nav {
//		float: right;
//		display: inline-block;
//		background: #fff;
//		padding: 0.2rem 0 0;
//		
//		@media #{$tbpc} {
//			padding: 0.3rem 0 0 1.6rem;
//		}
//		
//		a {
//			background: url(images/sp_icon_sublink.png) no-repeat scroll right 50%;
//			background-size: 7px 11px;
//			color: $baseFontThinColor;
//			padding: 0 1.2rem 0 0;
//			
//			@media #{$tbpc} {
//				background: none;
//				padding: 0;
//			}
//		}
//	}
//}

//小見出し
.c-heading {
	border-bottom: #979797 solid 1px;
	
	@media #{$sp} {
		font-size: 1.4rem;
		padding: 0 0 .8rem;
		margin: 0 $spSpace 1.1rem;
	}
	
	@media #{$tbpc} {
		font-size: 2.4rem;
		font-weight: normal;
		margin: 0 0 2.2rem;
		padding: 0 0 1.2rem;
	}
	
}

//小見出し
.c-subhead {
	font-size: 1.6rem;
	font-weight: bold;
	padding: 0 0 8px;
}