@charset "UTF-8";
// -----------------------------------------------------------------
// Mixin
// グローバルなmixinはここに格納します。それ以外はそのscssファイルのみで完結してください。
// -----------------------------------------------------------------


//tableレイアウト
@mixin table($value) {
	display: table;
	width: $value;
}

@mixin tableCell($value) {
	display: table-cell;
	vertical-align: $value;
}

//overflow型cleafix
@mixin flow($value) {
	width: $value;
	overflow: hidden;
}

//clearfix（Compass使わないのでここに書きます）
@mixin clearfix {
	zoom: 1;
	
	&:before,
	&:after {
		content: "";
		display: table;
	}
	
	&:after {
	clear: both;
	}
}

//SPのリンクのアイコン
@mixin spLinksIcon {
	background: url(images/icon_links_sp.png) no-repeat scroll right 50%;
	background-size: 5px 8px;
	padding: 0 1.2rem 0 0;
}

//PCのリンクのアイコン
@mixin pcLinksIcon {
	background: url(images/icon_links_blue.png) no-repeat scroll right 50%;
	background-size: 7px 10px;
	padding-right: 1.6rem;
}


//日付部分のグラデーション
@mixin gradDate {
	background: #FF6C6C;
	background-image: linear-gradient(#FF6C6C 0%, #F70F0F 100%);
}

// 各カテゴリのグラデーション
@mixin magazineGrd {
	background-image: linear-gradient(-180deg, #EABE1B 0%, #D78C2B 100%);
}
@mixin booksGrd {
	background-image: linear-gradient(-180deg, #98DC21 0%, #35970E 99%);
}
@mixin musicScoreGrd {
	background-image: linear-gradient(-180deg, #E873B1 0%, #E12C72 100%);
}
@mixin musicManualGrd {
	background-image: linear-gradient(-180deg, #C27CD2 0%, #8A2AAA 100%);
}
@mixin CdDvdOthersGrd {
	background-image: linear-gradient(-180deg, #23B9DC 0%, #1180BE 100%);
}

//アルファアニメーション
@mixin animeAlpha {
	transition: opacity 0.1s ease-out;
}

@mixin animeAlphaHover($value) {
	opacity: $value / 100;
	filter:alpha(opacity=$value);
}

//背景アニメーション
@mixin animeBg {
	transition: background 0.1s ease-out;
}

//カラーアニメーション
@mixin animeColor {
	transition: color 0.1s ease-out;
}


//placeholder
@mixin placeholder {
	&:placeholder-shown {
		@content;
	}
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		opacity: 1;
		@content;
	}
	&::-moz-placeholder {
		opacity: 1;
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}



//////////////////////////////////////////////////////////ここから下はold。消す予定

//ブロックリンクの設定
@mixin blockLinks {
	box-sizing: border-box;
	display: block;
	text-decoration: none;
}

