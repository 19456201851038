@charset "UTF-8";
// ==========================================================================
// Adversting
// 広告料ページ周りの設定
// ==========================================================================
.s-ad {
	&-head {
		width: 100%;
		display: table;
		margin-bottom: 40px;
	}
	&-logo {
		display: table-cell;
		width: 60%;
		vertical-align: top;
		img {
			width: 100%;
			margin-bottom: 30px;
		}
	}
	&-info {
		display: table-cell;
		width: auto;
		padding-left: 40px;
		vertical-align: top;
	}
	&-box {
		width: 100%;
		padding: 10px 20px;
		display: table;
		margin-bottom: 20px;
		border: 2px solid #ddd;
		border-radius: 2px;
		box-sizing: border-box;
	}
	&-distribution {
		display: table-cell;
		width: auto;
		vertical-align: top;
	}
	&-graph {
		display: table-cell;
		width: 300px;
		vertical-align: top;
		img {
			width: 100%;
		}
	}
}

@media #{$sp} {
	.s-ad {
		&-head {
			display: block;
		}
		&-logo {
			display: block;
			width: 100%;
		}
		&-info {
			display: block;
			width: 100%;
			padding-left: 0;
		}
		&-box {
			width: 100%;
			padding: 1rem;
			display: block;
			margin-bottom: 20px;
			border: 2px solid #ddd;
			border-radius: 2px;
			box-sizing: border-box;
		}
		&-distribution {
			display: block;
			width: 100%;
		}
		&-graph {
			display: block;
			width: 100%;
		}
	}
}