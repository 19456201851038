@charset "UTF-8";
// ==========================================================================
// Clearfix
// エディタ内のclearfixの設定
// ==========================================================================

.mceContentBody,
.post {
	.clearfix {
		*zoom: 1;
		
		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}
}