@charset "UTF-8";
// ==========================================================================
// Links
// リンクのパーツ
// ==========================================================================

//初期設定の色などは_base.scssに入れてます。


//ブロックリンクの設定
.c-block-link {
	@include blockLinks;
	
	&:hover {
		opacity: .7;
	}
}