@charset "UTF-8";
// ==========================================================================
// 404
// 404ページ
// ==========================================================================
.p-404 {
	color: #333;
	@media #{$sp} {
		padding: $spSpace $spSpace $spSpace*2;
		.p-404__title {
			font-size: 1.6rem;
			margin: 0 0 1.6rem;
			font-weight: bold;
			&--num {
				font-size: 1.5rem;
				margin: 0 0 .6rem;
			}
		}
		.p-404__box {
			line-height: 1.6;
			margin: 0 0 2rem;
			&--result {
				font-size: 2rem;
			}
		}
		.p-404__hint {
			width: 100%;
			margin: 0 auto 2rem;
			padding: 1.5rem;
			border: 1px solid #ddd;
			box-sizing: border-box;
			&--title {
				margin-bottom: 1rem;
				font-size: 2rem;
				text-align: center;
			}
			&--list {
				font-size: 1.4rem;
				li {
					padding: 0.3rem 0.3rem 0.3rem 1.7rem;
					text-indent: -1.9rem;
					&:before {
						content: '・ ';
						color: #51728c;
					}
				}
			}
		}
		.p-404__button {
			.p-404-button__item {
				margin: 0;
				&+.p-404-button__item {
					margin-top: 1rem;
				}
			}
			a {
				@include animeBg;
				border: $colorBaseThin solid 1px;
				background: $colorBaseThin;
				border-radius: $radius;
				color: #fff;
				display: block;
				padding: 1rem 2rem;
				text-align: center;
			}
		}
	}
	@media #{$tbpc} {
		max-width: 156rem;
		width: 100%;
		margin: 0 auto -5.6rem;
		padding: 1rem 0 12.6rem;
		.p-404__wrap {
			text-align: center;
		}
		.p-404__title {
			font-size: 3.6rem;
			margin: 0 0 3.6rem;
			&--gray {
				color: #9b9b9b;
				font-weight: bold;
			}
			&--num {
				font-size: 2.6rem;
				margin: 0 0 1.2rem;
			}
		}
		.p-404__box {
			font-size: 2rem;
			line-height: 1.6;
			margin: 0 0 5rem;
			&--result {
				font-size: 2.6rem;
			}
		}
		.p-404__hint {
			width: 66rem;
			margin: 7rem auto;
			padding: 3rem 6rem;
			text-align: left;
			border: 1px solid #ddd;
			box-sizing: border-box;
			&--title {
				margin-bottom: 1rem;
				font-size: 2rem;
			}
			&--list {
				font-size: 1.6rem;
				li {
					padding: 0.3rem 0.3rem 0.3rem 1.7rem;
					text-indent: -1.7rem;
					&:before {
						content: '・ ';
						color: #51728c;
					}
				}
			}
		}
		.p-404__button {
			margin-bottom: 5rem;
			.p-404-button__item {
				display: inline-block;
				padding: 0 2.4rem 0 0;
			}
			a {
				@include animeBg;
				border: $colorBaseThin solid 1px;
				background: $colorBaseThin;
				border-radius: $radius;
				color: #fff;
				display: block;
				padding: 1.6rem 4rem;
				&:hover {
					background: #fff;
					color: $colorBaseThin;
					text-decoration: none;
				}
			}
		}
	}
}