@charset "UTF-8";
// ==========================================================================
// Table
// エディタ内のテーブルの設定
// ==========================================================================
.mceContentBody,
.post {
	.table {
		&-wrap {
			overflow-x: auto;
		}
		&-01 {
			width: 100%;
			margin-bottom: 2rem;
			border: 1px solid #ddd;
			box-sizing: border-box;
			@media #{$sp} {
				width: 200%;
			}
			tr {
				&:nth-child(odd) {
					background-color: #ebf6fb;
				}
				&:first-child {
					color: white;
					background-color: #51728c;
				}
			}
			th,
			td {
				padding: 16px 20px;
				text-align: left;
				vertical-align: middle;
				font-size: 1.4rem;
			}
		}
		&-02 {
			width: 100%;
			margin-bottom: 2rem;
			border: 1px solid #979797;
			box-sizing: border-box;
			thead {
				color: #fff;
				tr th {
					width: auto;
					padding: 1rem;
					background-color: #444;
				}
			}
			tr {
				border-bottom: 1px solid #979797;
				&:last-child {
					border-bottom: none;
				}
				th {
					width: 20%;
					border-right: 1px solid #979797;
					background-color: #fafafa;
				}
				th,
				td {
					padding: 1.5rem;
				}
			}
		}
		&-03 {
			width: 100%;
			margin-bottom: 2rem;
			border-top: 1px solid #979797;
			tr {
				border-bottom: 1px solid #979797;
				th {
					width: 20%;
					vertical-align: middle;
					font-weight: normal;
					background-color: #f3f3f3;
				}
				td {
					padding: 2rem;
				}
			}
		}
		&-form {
			width: 100%;
			margin-bottom: 2rem;
			border-top: 1px solid #979797;
			border-collapse: separate;
			tr {
				th {
					width: 25%;
					vertical-align: middle;
					font-weight: normal;
					background-color: #f3f3f3;
					border-bottom: 1px solid #979797;
				}
				td {
					padding: 2rem;
					border-bottom: 1px solid #979797;
					.wpcf7-list-item input {
						width: auto;
					}
				}
			}
			input,
			select,
			textarea {
				width: 100%;
				padding: 0.8rem;
				font-size: 1.3rem;
				border: 1px solid #979797;
				background-color: #fff;
			}
			&--required {
				color: #fb2929;
				font-size: 1.1rem;
			}
		}
		&-score {
			margin-bottom: 2rem;
			td {
				padding: 0.3rem;
			}
		}
		&-corporate {
			width: 100%;
			border-top: 1px solid #ddd;
			tr {
				border-bottom: 1px solid #ddd;
				th,
				td {
					padding: 3rem 0;
					text-align: left;
					dl {
						display: table;
						width: 100%;
						margin: 0;
						@media #{$sp} {
							display: block;
						}
						dt {
							display: table-cell;
							width: 55%;
							padding-right: 5%;
							span {
								font-size: 1.2rem;
							}
							@media #{$sp} {
								display: inline-block;
								width: auto;
							}
						}
						dd {
							display: table-cell;
							width: auto;
							vertical-align: top;
							@media #{$sp} {
								display: inline-block;
							}
						}
					}
					@media #{$sp} {
						display: block;
						padding: 1rem 0;
					}
				}
				th {
					width: 12rem;
					padding-right: 1rem;
					color: #737173;
					font-size: 1.6rem;
					box-sizing: border-box;
					@media #{$sp} {
						width: 100%;
					}
				}
				td {
					font-size: 1.4rem;
				}
			}
			&__info {
				width: 100%;
				tr {
					border-bottom: 1px solid #ddd;
					&:last-child {
						border-bottom: none;
					}
					th,
					td {
						padding: 3rem 0;
						text-align: left;
						@media #{$sp} {
							display: block;
							padding: 1rem 0;
						}
					}
					th {
						width: 50%;
						color: #737173;
						font-size: 1.6rem;
						@media #{$sp} {
							width: 100%;
						}
					}
					td {
						font-size: 1.4rem;
					}
				}
			}
		}
	}
}
