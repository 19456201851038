@charset "UTF-8";
// ==========================================================================
// table
// テーブルのパーツ
// ==========================================================================
.c-table {
	width: 100%;
}

.c-table th,
.c-table td {
	border: $bdrColor solid 1px;
	padding: 0.8rem;
}

.c-table th {
	background: #fafafa;
	text-align: center;
	font-weight: normal;
	white-space: nowrap;
	@media #{$tbpc} {
		width: 20%;
	}
}

.c-table td {}
