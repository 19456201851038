@charset "UTF-8";
// ==========================================================================
// Headline
// エディタ内の見出しの設定
// ==========================================================================
.mceContentBody,
.post {
	h2 {
		margin: 6rem 0 3rem;
		padding-bottom: 1.5rem;
		position: relative;
		font-size: 2.4rem;
		font-weight: normal;
		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			position: absolute;
			bottom: 0;
			background-color: #979797;
		}
	}
	h3 {
		margin: 4rem 0 1rem;
		font-size: 1.6rem;
		font-weight: normal;
		&.access{
			margin-bottom: 3rem;
		}
	}
	h4 {
		margin: 2rem 0;
		font-size: 1.4rem;
		font-weight: bold;
	}
	h5 {
		margin: 2rem 0 1.2rem;
		font-size: 1.6rem;
		span{
			font-size: 1.3rem;
		}
	}
}