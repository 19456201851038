@charset "UTF-8";
// ==========================================================================
// Home
// TOPページ
// ==========================================================================

// ヘッダー部分
@media #{$sp} {
	.p-home-header-keyword {
		text-align: center;
		background: #fff;
		padding: 1.8rem 1.2rem .8rem;
		border: #9b9b9b solid 1px;
		border-radius: $radius;
		margin: 2rem 8% 0;
		position: relative;
	}

	.p-home-header-keyword__title {
		position: absolute;
		color: #fff;
		font-size: 1.1rem;
		top: -1.2rem;
		left: 0;
		right: 0;
		margin: 0 auto;

		p {
			background: #019ef7;
			border-radius: $radius;
			padding: .4rem 1rem;
			display: inline-block;
		}
	}

	.p-home-header-keyword__items {
		text-align: left;

		li {
			display: inline-block;
			padding: 0 0 .5rem;

			a {
				display: block;
				border-radius: $radius;
				font-size: 1.2rem;
				text-decoration: underline;
				padding: .1rem .5rem;
			}
		}
	}
}

@media #{$tbpc} {
	
	.header-search--home {
		.search-box {
			width: 50%;
		}
	}
	
	.p-home-header-keyword {
		@include tableCell(middle);
		margin: 0 auto;
		position: relative;

		.p-home-header-keyword__title {
			@include tableCell(middle);
			padding: 0 2.1rem 0 0;
			
			p {
				background: #737173;
				border-radius: $radius;
				color: #fff;
				font-weight: bold;
				font-size: 1.2rem;
				padding: .6rem 1.2rem;
				position: relative;
				white-space: nowrap;
				
				&:after {
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 3px 0 3px 6px;
					border-color: transparent transparent transparent #737173;
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto 0;
					right: -.6rem;
				}
			}
			
		}
		
		.p-home-header-keyword__items {
			@include tableCell(middle);

			li {
				display: inline-block;
				padding: .2rem 1.5rem .2rem 0;
			}
			a {
				display: block;
				text-decoration: underline;

				&:hover{
					text-decoration: none;
				}
			}
		}
	}
	
}

// コンテナの調整
.column--single-home {
	@media #{$tbpc} {
		padding: 0;
	}
}

// topバナー
.p-home-hero {
	@media #{$sp} {
		border-top: $colorBase solid 3px;
		
		.bx-controls-direction {
			display: none;
		}
	}
	
	@media #{$tbpc} {
		width: 100%;
		height: 100%;
		overflow-x: hidden;
		overflow-y: hidden;
		border-bottom: #f1f1f1 solid 1rem;
		//padding-bottom: 1rem;
		//background-color: #f1f1f1;
		position: relative;
		min-width: $pcFullWidth + $pcWrapSpace * 2;
		
		&:before {
			@include animeAlphaHover(30);
			background: #000;
			content: "";
			display: block;
			width: 50%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			margin: 0 0 0 -31rem;
			z-index: 10;
		}

		&:after {
			@include animeAlphaHover(30);
			background: #000;
			content: "";
			display: block;
			width: 50%;
			height: 100%;
			position: absolute;
			left: 50%;
			margin: 0 0 0 31rem;
			top: 0;
			z-index: 10;
		}
		
		.p-home-hero-inner {
			width: 62rem;
			height: 29rem;
			margin: 0 auto;
			position: relative;
		}
		
		.p-home-hero__wrap {
			width: 310rem;
			//position: absolute;
			left: 50%;
			margin-left: -155rem;
		}
		
		.bx-viewport {
		}
		
		.p-home-hero__body {
			width: 100%;
			
			li {
				width: 62rem;
			}
		}
		
		.bx-controls {}
		
		.bx-prev {
			background: url(images/icon_carousel_prev.png) no-repeat scroll 0 0;
			background-size: 42px 42px;
			width: 42px;
			height: 42px;
			position: absolute;
			margin: auto;
			right: 0;
			left: -62rem;
			top: 0;
			bottom: 0;
			font-size: 0;
			z-index: 1000;
		}
		.bx-next {
			background: url(images/icon_carousel_next.png) no-repeat scroll 0 0;
			background-size: 42px 42px;
			width: 42px;
			height: 42px;
			position: absolute;
			margin: auto;
			right: 0;
			//left: 62rem;
			left: 60rem;
			top: 0;
			bottom: 0;
			font-size: 0;
			z-index: 1000;
		}
	}
}
// ie9対応
.no-videoautoplay .p-home-hero {
	
	@media #{$tbpc} {
		&:after {
			margin: 0 0 0 31.4rem;
		}
	}
}

//hero下のリンク
.p-home-links {
	
	.p-home-links__body {
		overflow: hidden;
	}
	
	.p-home-links__item {
		float: left;
		
		&.p-home-links__item--guide {
			float: left;
		}
		
		&.p-home-links__item--support {
			float: left;
			margin-left: 10px;
		}
		
		&.p-home-links__item--others {
			float: left;
			margin-left: 10px;
		}
		
		.p-home-links-item__title {
			background: #fff;
			font-size: 1.4rem;
			display: inline-block;
			color: #737173;
			font-weight: bold;
			padding: 0 1rem;
			position: absolute;
			left: 2.5rem;
			top: -1rem;
		}
		
		.p-home-links-item__body {
			//@include table(100%);
			
			li {
				margin-left: 4px;
				float: left;
				&:first-child{
					margin-left: 0;
				}
				
				a {
					border-radius: 0 0 1rem 1rem;
					display: block;
					color: #fff;
					font-size: 1.2rem;
					width: 150px;
					//max-width: 12.4rem;
					text-align: center;
					padding: .8rem 0 1rem;
					
					&:hover {
						@include animeAlphaHover(70);
						text-decoration: none;
					}
				}
			}
		}
		
		&.p-home-links__item--guide {

			.p-home-links-item__body {
				
				li a {
					background: #8d9da0;
				}
			}
		}
		
		&.p-home-links__item--support {
			
			.p-home-links-item__body {
				
				li a {
					background: #beb39d;
				}
			}
		}
		
		&.p-home-links__item--others {
			
			.p-home-links-item__body {
				
				li a {
					background: #918a80;
				}
			}
		}
	}
}

//カテゴリ（spのみ）
.p-home-cat--sp {
	padding: $spSpace;
	
	.header-nav {
		display: block;
	}
}


//マガジン一覧系
.p-home-magazine {
	background: #f1f1f1;
	
	.p-home-magazine__body {
		background: #fff;
	}
	
	@media #{$sp} {
		padding: 1.8rem $spSpace $spSpace;
		
		.p-home-magazine__body {
			padding: 0 1.6rem 1.6rem;
			margin: 0 0 $spSpace;
		}
		
		.p-home-magazine__title {
			border-bottom: $bdrThinColor solid 1px;
			padding: $spSpace 0;
			
			.c-headline-nav__title {
				span {
					background: #fff url(images/icon_home_magazine@2x.png) no-repeat scroll 0 50%;
					background-size: 17.5px 14px;
					padding: 0 0 0 2.7rem;
				}
			}
		}
		
		.p-home-magazine__banner {
			
			li {
				@include table(100%);
				margin: 0 0 .1rem;
				
				a {
					@include tableCell(middle);
					width: 100%;
					height: 5rem;
					background: #fff;
					text-align: center;
					
					img {
						width: 30%;
						max-width: 8.8rem;
						vertical-align: middle;
						height: auto;
					}
				}
				
				&:nth-child(4) {
					a img {
						width: 26%;
					}
				}
			}
		}
	}
	
	@media #{$tbpc} {
		min-width: $pcFullWidth + $pcWrapSpace * 2;
		padding: 3.5rem 0;
		margin: 0 0 4rem;
		
		.p-home-magazine__body {
			margin: 0 0 3.5rem;
			padding: 3.2rem 6rem 0;
		}
		
		.p-home-magazine__title {
			.c-headline-nav__title {
				span {
					background: #fff url(images/icon_home_magazine@2x.png) no-repeat scroll 0 50%;
					background-size: 35px 28px;
					padding: 0 3.6rem 0 5.2rem;
					z-index: 2;
				}
				&:before {
					z-index: 0;
				}
			}
			.c-headline-nav__links {
				span {
					position: relative;
					z-index: 2;
				}
			}
		}
		
		.p-home-magazine__banner {
			ul {
				@include table(100%);
				table-layout: fixed;
				width: 115rem;
				margin: 0 auto;
			}
			
			li {
				@include tableCell(middle);
				
				a {
					box-sizing: border-box;
					border: $colorBdr solid 1px;
					display: block;
					width: 100%;
					line-height: 5rem;
					background: #fff;
					text-align: center;
					
					img {
						width: 40%;
						vertical-align: middle;
						height: auto;
					}
				}
			}
		}
	}
}

//ニュース
.p-home-news {
	.c-info-item {
		@media #{$sp} {
			padding: $spSpace 0;
		}
	}
}


//特集
.p-home-special {
	background: #f1f1f1;
	
	@media #{$sp} {
		padding: 0 0 1rem;
	}
	
	@media #{$tbpc} {
		padding: 4.8rem 0 0;
		min-width: $pcFullWidth + $pcWrapSpace * 2;
		//margin: 0 0 6rem;
		
		.c-headline-nav__title:before {
			z-index: 1;
		}
		
		.c-headline-nav__title span,
		.c-headline-nav__links{
			background: #f1f1f1;
			z-index: 2;
		}
	}
}


//新刊一覧
.js-home-new-release-wrap {
	max-width: 114rem;
	width: 100%;
	margin: 0 auto;
	position: relative;
}
.p-home-new-release {
	@media #{$sp} {
		.c-item-list--home {
			border-top: $bdrThinColor solid 1px;
			
			.c-item-list__item--home {
				padding: 0 $spSpace;
				position: relative;
			}
			.c-item-info__title--home {
				font-size: 1.3rem;
				margin: 0 0 .6rem;
			}
		}
	}
	@media #{$tbpc} {
		margin-left: -5rem;
		.c-item-list--home {
			letter-spacing: 0;
			-js-display: flex;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: space-around;
			width: 100%;
			padding: 0 0 8.4rem;
		}
		
		.c-item-list__item--home {
			box-sizing: border-box;
			width: 21rem;
			border: #E5E5E5 solid 1px;
			padding: 2rem 0 0 2rem;
			min-height: 39rem;
			
			.c-item-inner__cat {
				bottom: -1.5rem;
			}
			
			a {
				display: block;
				
				&:hover {
					cursor: default;
					text-decoration: none;
				}
			}
		}
		
		.c-item-inner__date-wrap {
			width: 16.8rem;
			text-align: center;
			
			.c-item-inner__date--home {
				margin-left: auto;
				margin-right: auto;
				width: 12.4rem;
			}
		}
		
		.c-item-inner--home {
			width: 14.8rem;
			padding: 0 2.2rem 0 0;
			
			.c-item-inner__thumb--home {
				box-sizing: border-box;
				position: relative;
				padding: 0 0 2rem 2.4rem;
				height: 171px;
				width: 100%;
				margin: 0 0 2rem;
				
				img {
					width: auto;
					box-sizing: border-box;
					max-height: 100%;
					position: absolute;
					bottom: 0;
					//padding: 0 0 1.5rem;
					
					&:hover {
						cursor: pointer;
					}
				}
			}
			
			.c-item-info__title--home {
				margin: 0 0 0.6rem;
				
				&:hover {
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}
		
		.bx-prev {
			background: url(images/icon_carousel_prev.png) no-repeat scroll 0 0;
			background-size: 42px 42px;
			width: 42px;
			height: 42px;
			position: absolute;
			margin: 17rem auto 0;
			left: -3rem;
			top: 0;
			bottom: 0;
			font-size: 0;
		}
		.bx-next {
			background: url(images/icon_carousel_next.png) no-repeat scroll 0 0;
			background-size: 42px 42px;
			width: 42px;
			height: 42px;
			position: absolute;
			margin: 17rem auto 0;
			right: -3rem;
			top: 0;
			bottom: 0;
			font-size: 0;
		}
		.bx-pager {
			position: absolute;
			right: 2rem;
			bottom: 5.5rem;
			text-align: right;
			
			.bx-pager-item {
				display: inline-block;
				padding: 0 0 0 1rem;
			}
			
			a {
				font-size: 0;
				display: inline-block;
				border-radius: 1.2rem;
				width: 1.2rem;
				height: 1.2rem;
				background: #D8D8D8;
				border: 1px solid #979797;
				
				&.active {
					border: $colorBase solid 1px;
					background: $colorBase;
				}
			}
		}
	}
}


//お知らせ
.p-home-news {
	@media #{$sp} {
		padding: 4rem $spSpace $spSpace;
		
		.p-home-news__body {
			.p-home-news-title__label {
				text-align: center;
				margin: -3rem auto 0;
				
				span {
					background: url(images/img_home_news_sp@2x.png) no-repeat scroll 50% 0;
					background-size: 60px 68px;
					display: block;
					width: 60px;
					height: 68px;
					margin: 0 auto;
				}
					
				img {
					display: none;
				}
			}
			
			.p-home-news-title__links {
				position: relative;
				text-align: right;
				margin: -1.8rem 0 0;
				
				span {
					color: $colorBase;
					@include spLinksIcon;
				}
			}
			
			.p-home-news__list {
				margin: 1.2rem 0 0;
				border-top: $bdrThinColor solid 1px;
			}
		}
		
		.p-home-news__banner {
			position: relative;
			
			li {
				text-align: center;
			}
			
			.bx-prev {
				background: url(images/icon_home_prev_sp.png) no-repeat scroll 0 0;
				background-size: 30px 30px;
				width: 3rem;
				height: 3rem;
				display: block;
				font-size: 0;
				position: absolute;
				left: .4rem;
				top: 0;
				bottom: 0;
				margin: auto 0;
			}
			.bx-next {
				background: url(images/icon_home_next_sp.png) no-repeat scroll 0 0;
				background-size: 30px 30px;
				width: 3rem;
				height: 3rem;
				display: block;
				font-size: 0;
				position: absolute;
				right: .4rem;
				top: 0;
				bottom: 0;
				margin: auto 0;
			}
		}
		
	}
	@media #{$tbpc} {
		padding: 3.5rem 0;
		
		.p-home-news__body {
			display: table;
			text-align: center;
			width: 115rem;
			margin: 0 auto;
			
			.p-home-news__title {
				display: table-cell;
				padding: 0 2rem 0 0;
				vertical-align: top;
			}
			
			.p-home-news__list {
				display: table-cell;
				max-width: 85rem;
				text-align: left;
				vertical-align: top;
			}
			
			.p-home-news__link{
				display: table-cell;
				vertical-align: middle;
				a{
					display: block;
					background: url(images/icon_carousel_next.png) no-repeat scroll 50% 0;
					background-size: 42px 42px;
					padding-top: 6rem;
					color: #000;
				}
			}
			
			.p-home-news-title__links {
				margin: 3.8rem auto 0;
				
				a {
					text-decoration: underline;
					color: $colorBase;
					font-weight: bold;
					
					&:hover {
						text-decoration: none;
					}
					
					span {
						@include pcLinksIcon;
					}
				}
			}
		}
		
		.p-home-news__banner {
			width: 115rem;
			margin: 0 auto;
			
			ul {
				width: 100%;
				-js-display: flex;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				li{
					margin: 0 auto; //バナーが一つだけの場合に中央に寄せるための記述 バナーが増えた場合は外してください
				}
			}
		}
	}
}

// バナー
.p-home-banner{
	@media #{$sp} {
		padding: $spSpace $spSpace 0;
		position: relative;
		background-color: #f1f1f1;
			
		li {
			text-align: center;
		}

		.bx-prev {
			background: url(images/icon_home_prev_sp.png) no-repeat scroll 0 bottom;
			background-size: 30px 30px;
			width: 3rem;
			height: 3rem;
			display: block;
			font-size: 0;
			position: absolute;
			left: .4rem;
			top: 0;
			bottom: 0;
			margin: auto 0;
			padding: .75rem 0 0;
		}
		.bx-next {
			background: url(images/icon_home_next_sp.png) no-repeat scroll 0 bottom;
			background-size: 30px 30px;
			width: 3rem;
			height: 3rem;
			display: block;
			font-size: 0;
			position: absolute;
			right: .4rem;
			top: 0;
			bottom: 0;
			margin: auto 0;
			padding: .75rem 0 0;
		}
	}

	@media #{$tbpc} {
		margin: 0 auto;
		padding-top: 3.5rem;
		background-color: #f1f1f1;
		min-width: $pcFullWidth + $pcWrapSpace * 2;
		
		ul {
			width: 115rem;
			display: table;
			margin: 0 auto;
			li{
				display: table-cell;
				width: 32%;
				img{
					width: 100%;
				}
				&+li{
					width: 34%;
					padding-left: 2%;
				}
			}
		}
	}
}


// レポート
.p-home-report {
	background: #f1f1f1;
	
	@media #{$sp} {
		
	}
	@media #{$tbpc} {
		padding: 1.4rem 0 1.5rem;
		margin: 0 0 4rem;
		min-width: $pcFullWidth + $pcWrapSpace * 2;
		
		.c-headline-nav__title:before {
			z-index: 1;
		}
		
		.c-headline-nav__title span,
		.c-headline-nav__links{
			background: #f1f1f1;
			z-index: 2;
		}
	}
}


//おすすめコンテンツ
.rec-cntnts {
	
	@media #{$sp} {
		padding: 0 $spSpace $spSpace;
		
		&__body {
			width: 100%;
			overflow: hidden;
		}
		
		&__item {
			width: 48%;
			float: left;
			padding: 0 2% 1rem 0;
			
			&:nth-child(even) {
				float: right;
				padding: 0 0 1rem 2%;
			}
		}
	}
	
	@media #{$tbpc} {
		&__body {
			padding: 6.5rem 0 2.5rem;
			width: 100%;
			overflow: hidden;
			text-align: center;
		}

		&__item {
			display: inline-block;
			padding: 1rem;
		}
	}
}