@charset "UTF-8";
// ==========================================================================
// fair
// フェア周りのページの設定
// ==========================================================================
.fair,
.rockfair,
.rockandread,
.hokkaido,
.tohoku,
.cyubu,
.kinki,
.cyugoku,
.kyusyu,
.web {
	.post {
		img{
			width: 100%;
		}
	}
}