@charset "UTF-8";
// ==========================================================================
// New Release
// 新刊情報
// ==========================================================================

@media #{$sp} {
	.p-newrelaease__side {
		.p-newrelaease-side__title {
			position: relative;
			
			&:after {
				background: url(images/icon_open_close_sp@2x.png) no-repeat scroll 0 bottom;
				background-size: 15px 30px;
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 1rem;
				width: 1.5rem;
				height: 1.5rem;
				margin: auto 0;
			}
			
			&.p-newrelaease-side__title--active {
				&:after {
					background: url(images/icon_open_close_sp@2x.png) no-repeat scroll 0 top;
					background-size: 15px 30px;
				}
			}
		}
	}
	
	.p-newrelaease__archive {
		display: none;
	}
}

@media #{$tbpc} {
	.p-newrelaease-list {
		.c-item-list {
			border-top: #E6E6E6 solid 1px;
			border-left: #E6E6E6 solid 1px;
			box-sizing: border-box;
			
			.c-item-list__item {
				width: 50%;
				box-sizing: border-box;
				border-right: #E6E6E6 solid 1px;
				border-bottom: #E6E6E6 solid 1px;
				padding: 2.8rem 2.8rem 3.2rem;
			}

			.c-item-inner {
				@include table(100%);
				box-sizing: border-box;
				table-layout: auto;
				position: relative;

			}
			.c-item-inner__thumb {
				@include tableCell(middle);
				padding: 0 1.5rem 0 0;
				width: 25%;
				height: 100%;
				position: static;

				img {
					width: 100%;
					height: auto;
					max-height: 100%;
				}

				.c-item-inner__cat {
					top: 0;
					right: 0;
					bottom: inherit;
					left: inherit;
				}
			}
			.c-item-inner__info {
				@include tableCell(top);
				padding: .4rem 6rem 0 0;
				box-sizing: border-box;
			}
		}
	}
}