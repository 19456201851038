@charset "UTF-8";
// ==========================================================================
// Ranking
// 売れ筋商品
// ==========================================================================

$rank: first, second, third;

.p-ranking-list {
	@media #{$sp} {
		.c-item-inner__info:before {
			font-family: $fontFamilyRank;
			color: #1A1A1A;
			display: inline-block;
			padding: 0 0 1rem;
			font-weight: bold;
			text-align: left;
		}
		
		@for $i from 1 through 50 {
			li:nth-child(#{$i}) {
				.c-item-inner__info:before {
					content: '#{$i}位';
				}
			}
		}
		
		@each $value in $rank {
			$key: index($rank, $value);
			li:nth-child(#{$key}){
				.c-item-inner__info:before {
					background: url(images/icon_ranking_#{nth($rank, $key)}@2x.png) no-repeat scroll 0 1px;
					background-size: 22px 14px;
					padding: 0 0 0 2.6rem;
				}
			}
		}
	}
	
	@media #{$tbpc} {
		.c-item-inner {
			position: relative;
		}
		.c-item-inner:before {
			font-family: $fontFamilyRank;
			color: #1A1A1A;
			content: "";
			font-weight: normal;
			font-size: 2.6rem;
			display: block;
			padding: 0 0 0 1rem;
		}
		
		@for $i from 1 through 50 {
			li:nth-child(#{$i}) {
				.c-item-inner:before {
					content: '#{$i}';
				}
			}
		}
		
		@each $value in $rank {
			$key: index($rank, $value);
			li:nth-child(#{$key}){
				.c-item-inner:before {
					background: url(images/icon_ranking_#{nth($rank, $key)}@2x.png) no-repeat scroll 1rem 0;
					background-size: 30px 19px;
					padding: 2rem 0 0 1rem;
				}
			}
		}
		
		@for $i from 1 through 3 {
			li:nth-child(#{$i}) {
				.c-item-inner__thumb {
					height: 21.2rem;
					vertical-align: bottom;
				}
			}
		}
		
		@for $i from 4 through 5 {
			li:nth-child(#{$i}) {
				.c-item-inner {
					padding-top: 3.6rem;
				}
				.c-item-inner:before {
					padding: 2rem 0 0 1rem;
				}
			}
		}

		.c-item-inner__thumb:before {
			content: "位";
			display: block;
			position: absolute;
			color: $colorBase;
			top: -2.5rem;
			left: 4.4rem;
			font-size: 1.4rem;
			font-weight: bold;
		}
		@for $i from 1 through 9 {
			li:nth-child(#{$i}) {
				.c-item-inner__thumb:before {
					left: 3rem;
				}
			}
		}
		
	}
}

