@charset "UTF-8";
// ==========================================================================
// Sinlge Item
// 商品詳細
// ==========================================================================

.p-item {
	@media #{$sp} {
		padding: 2rem 0 2rem;
	}
	@media #{$tbpc} {
		margin-bottom: 4rem;
		position: relative;
	}
}

//タイトル周り
@media #{$sp} {
	.p-item__head {
		margin: 2rem 0 0;
		padding: 0 $spSpace;
		
		.p-item-head__title {
			font-size: 1.8rem;
			font-weight: bold;
			margin: 0 0 1rem;
		}
	}
}
@media #{$tbpc} {
	.p-item__sub-head {
		margin: 0 auto 2.5rem;
		padding: 0;
		
		.p-item-sub-head__title {
			font-size: 2.6rem;
			margin: 0 0 2rem;
		}
	}
	
}


// ヘッドのカテゴリ
.p-item-head__cat {
	
	@media #{$sp} {
		.main-header__item-cat {
			li {
				display: inline;
				color: $colorBaseThinner;
				font-size: 1.2rem;

				a {
					color: $colorBaseThinner;
				}
				
				&:last-child span {
					display: none;
				}
			}
			
		}
	}
	
	@media #{$tbpc} {
		display: table-cell;
		vertical-align: middle;
		
		.main-header__item-cat {
			margin: 2.2rem 0 0;
		}
		
		ul {
			//border-left: #979797 solid 1px;
			display: table;
		}
		li {
			border-top: #979797 solid 1px;
			border-bottom: #979797 solid 1px;
			color: $baseFontThinColor;
			display: table-cell;
			vertical-align: middle;
			position: relative;
			padding: 0.1rem 0.4rem 0.1rem 1.6rem;
			&:first-child {
				border-radius: $radius 0 0 $radius;
				padding-left: 1rem;
			}
			&:before {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 12px 0 12px 12px;
				border-color: transparent transparent transparent #979797;
				position: absolute;
				right: -12px;
				top: -1px;
			}
			&:after {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 11px 0 11px 11px;
				border-color: transparent transparent transparent #fff;
				position: absolute;
				right: -11px;
				top: 0;
			}
			
			a {
				color: #333;
				display: block;
			}
			
			span {
				display: none;
			}
		}
		
		.main-header__item-cat--home {
			background: $colorBaseThin;
			
			&:after {
				border-color: transparent transparent transparent $colorBaseThin;
			}
			
			a {
				background: url(images/icon_breadcrumb_home.png) no-repeat scroll 0 50%;
				background-size: 13px 10px;
				padding: 0 0 0 20px;
				color: #fff;
			}
			
		}
	}
}


//タイトル下の価格（pcのみ）
.p-item-head__buy {
	width: 100%;
	overflow: hidden;
	
	.p-item-head__buy-price {
		float: left;
		padding: 0 2.4rem 0 0;
	}
	
	.p-item-head__buy-price--tax {
		color: $colorPrice;
		font-size: 1.8rem;
		font-weight: bold;
		
		i {
			font-size: 1.4rem;
			font-style: normal;
		}
	}
	.p-item-head__buy-price--base {
		font-size: 1.3rem;
	}
}



//左の画像カラム
.p-item-thumb {
	@media #{$sp} {
		background: $colorBgThinner;
		padding: $spSpace;
		margin: 0 0 2.4rem;
	}
	
	.p-item-thumb__main {
		margin: 0 0 $spSpace;
		
		@media #{$sp} {
			img {
				max-width: 24rem;
				height: auto;
				margin: 0 auto;
			}
		}
		
		@media #{$tbpc} {
			margin: 0 0 1.7rem;
		}
		
		p {
			text-align: center;
			
			@media #{$tbpc} {
			}
		}
		
		img{
			width: 100%;
			height: auto;
			box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.22);
			
			@media #{$tbpc} {
				width: 100%;
				height: auto;
				//cursor: pointer;
			}
		}
	}
	
	.p-item-thumb__list {
		display: none;
		
		@media #{$tbpc} {
			display: block;
		}
	}
	
	// 立ち読みボタン
	.p-item-thumb__button {
		a {
			border: $colorBdr solid 1px;
			border-radius: $radius;
			background: #fff;
			color: $colorBase;
			font-weight: bold;
			display: block;
			text-align: center;
			padding: 1.2rem 0;
			
			span {
				background: url(images/icon_item_browse_pc@2x.png) no-repeat scroll 0 50%;
				background-size: 18px 18px;
				display: inline-block;
				padding: .4rem 0 .4rem 2.8rem;
			}
		}
		
		&.p-item-thumb__button--browse {
			span {
				background: url(images/icon_item_browse.png) no-repeat scroll 0 50%;
				background-size: 17px 22px;
				display: inline-block;
				padding: .4rem 0 .4rem 2.8rem;
			}
		}
		
		@media #{$tbpc} {
			display: none;
		}
	}
	
}

// サムネイル周り（PCのみ）
@media #{$tbpc} {
	.p-item-thumb__list div{
		width: 100%;
		-js-display: flex;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content: space-around;
		
		figure {
			padding: 0 8% 1rem 0;
			width: 27.6%;
			
			&:nth-child(4n) {
				padding: 0 0 1rem 0;
			}
			
			&:nth-child(n+4) {
				//display: none;
			}
			
			a {
				border: #ccc solid 1px;
				box-sizing: border-box;
				display: block;
				width: 100%;
				height: 7.4rem;
				overflow: hidden;
			}
		}
		
		img {
			//box-sizing: border-box;
			width: 100%;
			height: auto;
			
			&.js-thumb-images--w {
				width: auto;
				height: 7.4rem;
				max-width: inherit;
				max-height: 7.4rem;
			}
		}
		
		.image-cover-group--active {
			border: $colorLink solid 3px;
			margin: -.3rem;
		}
	}
	
	.p-item-thumb__links {
		margin: .8rem 0 0;
		padding: 0 0 0 2.5rem;
		background: url(images/icon_item_browse_pc@2x.png) no-repeat scroll 0 50%;
		background-size: 20px 20px;
	}
	
	.p-item-thumb__links--browse {
		background: url(images/icon_item_browse.png) no-repeat scroll 0 50%;
		background-size: 15px 20px;
	}
}

//ie9用
@media #{$tbpc} {
	.no-flexwrap {
		.p-item-thumb__list div{
			figure {
				display: inline-block;
			}
		}
	}
}


//中央のカラム
.p-item-info {
	@media #{$sp} {
		margin: 0 0 3rem;
		
		.p-item-info__revised {
			padding: 0 $spSpace 2rem;
			
			a {
				border: #979797 solid 1px;
				border-radius: .2rem;
				display: block;
				vertical-align: bottom;
				padding: 0 1rem;

				span {
					background: url(images/icon_item_revised@2x.png) no-repeat scroll 0 50%;
					background-size: 15px 15px;
					padding: .4rem 0 .4rem 2.2rem;
					display: block;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

// 表
.p-item-info__detail {
	@media #{$sp} {
		padding: 0 $spSpace 2.4rem;
	}
	@media #{$tbpc} {
		margin: 0 0 1.2rem;
	}
	
	.p-item-info__table {
		width: 100%;
		border-spacing: 3px 0;
		border-collapse: separate;
		
		th {
			color: $colorBaseThin;
			font-weight: normal;
			border-bottom: #ccc solid 1px;
			text-align: left;
			padding: .6rem 0;
			width: 20%;
			
			@media #{$sp} {
				width: 25%;
			}
		}
		
		td {
			border-bottom: #ccc solid 1px;
			padding: .6rem 0;
		}
		
		tr:first-child {
			th {
				border-top: #ccc solid 1px;
			}
			td {
				border-top: #ccc solid 1px;
			}
		}
	}
}

.p-item-info__sub {
	@media #{$sp} {
		padding: 0 $spSpace 2.4rem;
	}
	@media #{$tbpc} {
		padding: 0 0 3rem;
	}
	
	// バックナンバーリンク
	.p-item-info__backnumber {
		text-align: right;

		@media #{$sp} {
			margin: 0 0 2rem;
			
			a {
				@include spLinksIcon;
			}
		}
		@media #{$tbpc} {
			margin: 0 0 4rem;
			
			a {
				font-size: 1.4rem;
				
				&:hover {
					text-decoration: underline;
				}
				span {
					@include pcLinksIcon;
				}
			}
		}
	}
	
	//外部ボタン
	.p-item-info__sub-button {
		border-bottom: #ccc solid 1px;
		margin: 0 0 1rem;
		
		@media #{$sp} {
			p {
				margin: 0 0 1.6rem;
			}
		}
		@media #{$tbpc} {
			@include table(100%);
			//table-layout: fixed;
			margin: 0 0 1.6rem;
			
		}
		
		.p-item-info__sub-button--web {
			background: url(images/icon_item_detail_web@2x.png) no-repeat scroll 0 0;
			background-size: 22px 22px;
			padding: .2rem 0 .2rem 2.6rem;
			margin: 0 0 .4rem;
			
			a {
				color: $colorBase;
			}
		}
		
		.p-item-info__sub-button--twitter {
			background: url(images/icon_item_detail_twitter@2x.png) no-repeat scroll 0 0;
			background-size: 22px 22px;
			padding: .2rem 0 .2rem 2.6rem;
			margin: 0 0 .4rem;
		}
	}
}


// 商品追記エリア
.p-item-notice {
	margin: 2.4rem 0 0;
	
	@media #{$sp} {
		margin: 0 $spSpace;
	}
		
	&__title {
		position: relative;
		
		p {
			background: #fff;
			display: inline-block;
			padding: 0 1rem 0 0;
			
			span {
				border: #5cab11 solid 2px;
				background: #fff;
				border-radius: 3rem;
				font-size: 1.4rem;
				font-weight: bold;
				color: #5cab11;
				display: inline-block;
				padding: .3rem 1.6rem;
			}
			
			&:before {
				content: "";
				background: #5cab11;
				width: 100%;
				height: 2px;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				margin: auto 0;
				z-index: -1;
			}
		}
	}
	
	.p-item-notice__body {
		margin: 1rem 0 0;
	}
}

//右のカラム
// 購入エリア
.p-item-buy {
	.p-item-buy__price--tax {
		color: $colorPrice;
		font-weight: bold;
	}
	
	.p-item-buy__button--amazon {
		background: $colorAmazon;
		border-radius: $radius;
		color: #fff;
		font-weight: bold;
		display: block;
	}
	.p-item-buy__button--free {
		background: #fff;
		border: #f6e6d0 solid 1px;
		color: $colorBase;
		margin: 1rem 0 0;
		font-size: 1.2rem;
		font-weight: bold;
		display: block;
	}
	.p-item-buy__button--disable {
		border-radius: $radius;
		font-size: 1.2rem;
		text-align: center;
		line-height: 1.33;
		padding: .9rem 0;
	}
}

@media #{$sp} {
	.p-item-buy {
		background: $colorBgThinner;
		padding: $spSpace;
		text-align: center;
		//margin: 0 0 .8rem;
	}
	.p-item-buy--top {
		padding: 0;
		margin: 1.6rem 0 0;
	}
	
	.p-item-buy__price {
		margin: 0 0 1.1rem;
		
		.p-item-buy__price--tax {
			display: block;
			font-size: 1.5rem;
		}
		.p-item-buy__price--base {
			display: block;
			font-size: 1.2rem;
			color: $colorBaseThinner;
		}
	}
	
	.p-item-buy__button--amazon {
		font-size: 1.5rem;
		padding: 1.5rem 0;
	}
	
	.p-item-buy__button--free{
		padding: 1.2rem 0;
	}
	
	.p-item-buy__button--disable {
		background: #fff;
		color: $colorBaseThinner;
		padding: 1rem 0;
	}
}

@media #{$tbpc} {
	.p-item-buy {
		background: #f9f6e4;
		box-sizing: border-box;
		border: #FFD59B solid 5px;
		padding: 1.7rem 2.2rem 3rem;
		margin: 0 0 2.5rem;
	}
	
	.p-item-buy__guide {
		text-align: center;
		font-size: 1.5rem;
		color: $colorPrice;
		font-weight: bold;
		margin: 0 auto 1.6rem;
	}
	
	.p-item-buy__thumb {
		display: none;
	}
	
	.p-item-buy__title {
		display: none;
	}
	
	.p-item-buy__price {
		display: none;
	}
	
	
	//固定されたとき
	.p-item-buy--fixed {
		position: fixed;
		width: 26rem;
		top: 1rem;
		z-index: 1000;
		
		.p-item-buy__info {
			@include table(100%);
			padding: 0 0 1.3rem;

			.p-item-buy__thumb {
				@include tableCell(top);
				width: 6rem;
				padding: 0 1.4rem 0 0;

				img {
					width: 100%;
					height: auto;
				}
			}

			.p-item-buy__detail {
				@include tableCell(top);
			}
			
			.p-item-buy__guide {
				display: none;
			}

			.p-item-buy__title {
				display: block;
				margin: 0 0 .6rem;
			}
			
			.p-item-buy__price {
				text-align: left;
				display: block;
			}
			
			.p-item-buy__price--tax {
				display: block;
				font-size: 1.6rem;
			}
			.p-item-buy__price--base {
				display: block;
				font-size: 1.1rem;
			}
		}
	}
	
	.p-item-buy--release {
		position: absolute !important;
		top: inherit !important;
		bottom: 0;
		z-index: 1000;
		margin-bottom: 0;
	}
	
	
	.p-item-buy__button--amazon {
		@include animeAlpha;
		text-align: center;
		font-size: 1.6rem;
		padding: 1.4rem 0;
		
		&:hover {
			@include animeAlphaHover(80)
			text-decoration: none;
		}
	}
	
	.p-item-buy__button--free{
		text-align: center;
		font-size: 1.2rem;
		padding: 1rem 0;
		
		&:hover {
			text-decoration: none;
		}
	}
	
	.p-item-buy__button--disable {
		background: #eae5c7;
		color: #594f12;
	}
}


// シェア
.p-item-social {
	table-layout: fixed;
	background: #ECF7FF;
	border: #e0e0e0 solid 1px;
	border-radius: $radius;
	box-sizing: border-box;
	margin: 0 0 5rem;
	padding: .7rem;
	
	@media #{$sp} {
		margin: 0 $spSpace 2.4rem;
	}
	@media #{$tbpc} {
		@include table(100%);
	}
	
	.p-item-social__title {
		@include tableCell(middle);
		//white-space: nowrap;
		
		@media #{$sp} {
			font-size: 1rem;
			margin: 0 0 1.4rem;
			width: 100%;
			white-space: nowrap;
		}
		@media #{$tbpc} {
			margin: 0 0 1.5rem;
			padding-left: 1rem;
		}
	}
	
	.p-item-social__body {
		@include tableCell(middle);
		
		@media #{$tbpc} {
			width: 7.5rem;
			padding: 0 .8rem 0 0;
		}
	}
	
	ul {
		@include table(100%);
		table-layout: fixed;
		
		@media #{$sp} {
			width: 4.5rem * 3;
		}
		
		li {
			@include tableCell(middle);
		}
		
		a {
			display: block;
		}
	}
	
	@media #{$sp} {
		li {
			a {
				width: 4.5rem;
				height: 4.5rem;
			}
		}
		
		.p-item-social__button--tw a {
			background: #55acee url(images/icon_header_twitter.png) no-repeat scroll 50% 50%;
			background-size: 16px 16px;
		}
		.p-item-social__button--fb a {
			background: #3b5998 url(images/icon_header_facebook.png) no-repeat scroll 50% 50%;
			background-size: 16px 16px;
		}
		.p-item-social__button--ln a {
			background: #00d014 url(images/icon_social_line.png) no-repeat scroll 50% 50%;
			background-size: 27px 26px;
		}
	}
	@media #{$tbpc} {
		li {
			box-sizing: border-box;
			width: 50%;
			padding: 0 .3rem 0 0;
			
			&:nth-child(even) {
				padding: 0 0 0 .5rem;
			}
			
			a {
				height: 3.5rem;
				width: 3.5rem;
			}
		}
		
		.p-item-social__button--tw a {
			background: #55acee url(images/icon_header_twitter.png) no-repeat scroll 50% 50%;
			background-size: 14px 14px;
		}
		.p-item-social__button--fb a {
			background: #3b5998 url(images/icon_header_facebook.png) no-repeat scroll 50% 50%;
			background-size: 14px 14px;
		}
		.p-item-social__button--ln a {
			background: #00d014 url(images/icon_social_line.png) no-repeat scroll 50% 50%;
			background-size: 27px 26px;
		}
	}
}


// 関連アーティスト
.p-item-artist {
	@media #{$sp} {
		padding: 1.6rem $spSpace 0;
	}
	
	.p-item-artist__title {
		font-size: 1.5rem;
		font-weight: bold;
		margin: 0 0 0.6rem;
	}
	
	li {
		margin: 0 0 .2rem 2rem;
		list-style: disc;
	}
	
	a {
		&:hover {
			text-decoration: underline;
		}
	}
}

//関連タグ
.p-item-tags {
	@media #{$sp} {
		margin: 1.6rem 0 0;
		padding: 0 $spSpace;
	}
	
	@media #{$tbpc} {
		margin: 2.4rem 0 0;
	}
	
	.p-item-tags__title {
		font-size: 1.5rem;
		font-weight: bold;
		margin: 0 0 0.6rem;
	}
	
	li {
		margin: .2rem 0 0 2rem;
		list-style: disc;
	}
	
	a {
		&:hover {
			text-decoration: underline;
		}
	}
}

//改訂版リンクの表示
.p-item__revised {
	border: $colorAccent solid 1px;
	text-align: center;
	
	.p-item-revised__title {
		color: $colorAccent;
		font-weight: bold;
		
		span {
			&:before {
				background: $colorAccent;
				border-radius: 2rem;
				content: "!";
				color: #fff;
				display: inline-block;
				margin: 0 .5rem 0 0;
				width: 1.8rem;
				line-height: 1.8rem;
			}
		}
	}
	
	@media #{$sp} {
		margin: 0 $spSpace;
		padding: 1.2rem;
		
		.p-item-revised__title {
			margin: 0 0 .8rem;
		}
	}
	@media #{$tbpc} {
		padding: 1.2rem;
		
		.p-item-revised__title {
			margin: 0 0 .5rem;
		}
	}
}

