@charset "UTF-8";
// ==========================================================================
// Pager
// ページャーのパーツ
// ==========================================================================

@media #{$sp} {
	.c-pager {
		box-sizing: border-box;
		background: $colorBaseThin;
		color: #fff;
		padding: $spSpace;
		width: 100%;
		overflow: hidden;
		text-align: center;
		position: relative;
		
		.c-pager__num {
			box-sizing: border-box;
			display: inline-block;
			padding: 0 $spSpace;
			color: #fff;
			position: absolute;
			//width: 100%;
			text-align: center;
			line-height: 1;
			height: 1.2rem;
			margin: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			
		}
		
		a {
			
			p {
				background: #fff;
				border-radius: 2px;
				color: $colorBase;
				font-size: 1.2rem;
				padding: 1.2rem 1rem;
				font-weight: bold;
			}
			span {
				display: block;
				padding: 0 1.2rem;
			}
		}
		
		.c-pager--prev {
			display: inline-block;
			float: left;
			position: relative;
			
			span {
				background: url(images/icon_links_reverse_sp.png) no-repeat scroll left 50%;
				background-size: 5px 8px;
			}
		}
		
		.c-pager--next {
			display: inline-block;
			float: right;
			position: relative;
			
			span {
				background: url(images/icon_links_sp.png) no-repeat scroll right 50%;
				background-size: 5px 8px;
			}
		}
		
		.c-pager__nav {
			display: none;
		}
	}
}


@media #{$tbpc} {
	.c-pager {
		width: 100%;
		overflow: hidden;
		text-align: center;
		
		.c-pager__num {
			display: none;
		}
		
		.c-pager__nav {
			display: inline-block;
			text-align: center;
			
			li {
				display: inline-block;
				font-size: 1.2rem;
				
				&.current {
					background: $colorAccent;
					border-radius: 3px;
					color: #fff;
					font-weight: bold;
					padding: 1.1rem 1.4rem;
					margin: 1 .2rem 0 0;
				}
				
				a {
					@include animeAlpha;
					background: #E5E5E5;
					border-radius: 3px;
					color: $colorBase;
					display: block;
					padding: 1.1rem 1.4rem;
					font-weight: bold;
					text-decoration: none;
					margin: 1 .2rem 0 0;
					
					&:hover {
						@include animeAlphaHover(80);
					}
				}
			}
			.next, .previous {
				a {
					background: none;
					display: inline-block;
					color: $colorBase;
					font-weight: normal;
					padding: 0;
				}
			}
			.previous {
				padding: 0 1rem 0 0;
				
				a {
					background: url(images/icon_links_prev.png) no-repeat scroll 0 50%;
					background-size: 7px 10px;
					padding: 0 0 0 1.2rem;
					
					&:hover {
						text-decoration: underline;
					}
				}
			}
			.next {
				padding: 0 0 0 1rem;
				
				a {
					background: url(images/icon_links.png) no-repeat scroll right 50%;
					background-size: 7px 10px;
					padding: 0 1.2rem 0 0;
					
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		
		.c-pager--prev {
			display: none;
		}
		
		.c-pager--next {
			display: none;
		}
		
		.c-pager__guide {
			text-align: center;
			padding: 1.2rem 0 0;
			
			i {
				margin: 0 .3rem 0 .2rem;
			}
		}
	}
}